/* General styles for community pages (Journal and Press Releases) */

.view-journal-list,
.view-press-releases-list,
.view-author-archive{
  .card {
    margin-bottom: $grid-gutter-width;
    &:not(.author-archive){
      border-bottom: 1px solid $color18;
    }
    .review{
      padding: 0;
      border: 0;
    }
  }
}
