.wp-brands {
  $cols: 3;
  $logo-width: 200px;

  .brand {
    margin-bottom: $gutter-with-sm;
    width: 100%;

    img {
      max-width: $logo-width;
    }
  }

  .main-title {
    margin-top: 0;
    margin-bottom: 60px;
  }

  @include media(">=medium") {
    .brand {
      margin-right: $gutter-with-sm;
      width: calc(100% / #{$cols} - #{$grid-gutter-width});

      img {
        max-width: 175px;
      }
    }

    .brand:nth-of-type(#{$cols}n) {
      margin-right: 0;
    }

    .brands {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  @include media(">=large") {
    .brand {
      img {
        max-width: $logo-width;
      }
    }
  }
}
