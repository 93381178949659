/* This stylesheet contains styles regarding the co-branding section. */

.co-branded-shown{
  display: none;
}

.co-branded-page{

  .droptab-selector{
    .dropdown-menu{
      >li{
        padding: 0px;
        margin: 0px;
        >a.co-branded-hidden{
          display: none;
        }
      }
    }
  }
  .co-branded-hidden{
    display: none;
  }

  .co-branded-shown{
    display: block;
  }
  .co-branded-remove-padding-bottom{
    padding-bottom: 0px;
  }
}