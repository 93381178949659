#awards-accolades {
    $award-width: 717px;

    .award {
      max-width: $award-width;
    }

    .wp-static-content {
      margin-bottom: 60px;

      .wrapper {
        width: 100%;
        max-width: $award-width;
      }
    }
}
