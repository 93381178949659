// Typography
// -----------------------------------------------------------------------------
@font-face {
    font-family: "source_sans_probold";
    src: url("../fonts/sanspro-bold-webfont.eot");
    src: url("../fonts/sanspro-bold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-bold-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-bold-webfont.woff") format("woff"),
         url("../fonts/sanspro-bold-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-bold-webfont.svg#source_sans_probold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "source_sans_prosemibold";
    src: url("../fonts/sanspro-semibold-webfont.eot");
    src: url("../fonts/sanspro-semibold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-semibold-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-semibold-webfont.woff") format("woff"),
         url("../fonts/sanspro-semibold-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-semibold-webfont.svg#source_sans_prosemibold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "source_sans_proitalic";
    src: url("../fonts/sanspro-italic-webfont.eot");
    src: url("../fonts/sanspro-italic-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-italic-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-italic-webfont.woff") format("woff"),
         url("../fonts/sanspro-italic-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-italic-webfont.svg#source_sans_proitalic") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "source_sans_proregular";
    src: url("../fonts/sanspro-regular-webfont.eot");
    src: url("../fonts/sanspro-regular-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-regular-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-regular-webfont.woff") format("woff"),
         url("../fonts/sanspro-regular-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-regular-webfont.svg#source_sans_proregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "source_sans_prolight";
    src: url("../fonts/sanspro-light-webfont.eot");
    src: url("../fonts/sanspro-light-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-light-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-light-webfont.woff") format("woff"),
         url("../fonts/sanspro-light-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-light-webfont.svg#source_sans_prolight") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "source_sans_lightit";
    src: url("../fonts/sanspro-lightitalic-webfont.eot");
    src: url("../fonts/sanspro-lightitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/sanspro-lightitalic-webfont.woff2") format("woff2"),
         url("../fonts/sanspro-lightitalic-webfont.woff") format("woff"),
         url("../fonts/sanspro-lightitalic-webfont.ttf") format("truetype"),
         url("../fonts/sanspro-lightitalic-webfont.svg#source_sans_lightit") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "lorabold";
    src: url("../fonts/lora-bold-webfont.eot");
    src: url("../fonts/lora-bold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/lora-bold-webfont.woff2") format("woff2"),
         url("../fonts/lora-bold-webfont.woff") format("woff"),
         url("../fonts/lora-bold-webfont.ttf") format("truetype"),
         url("../fonts/lora-bold-webfont.svg#lorabold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "loraitalic";
    src: url("../fonts/lora-italic-webfont.eot");
    src: url("../fonts/lora-italic-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/lora-italic-webfont.woff2") format("woff2"),
         url("../fonts/lora-italic-webfont.woff") format("woff"),
         url("../fonts/lora-italic-webfont.ttf") format("truetype"),
         url("../fonts/lora-italic-webfont.svg#loraitalic") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "loraregular";
    src: url("../fonts/lora-regular-webfont.eot");
    src: url("../fonts/lora-regular-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/lora-regular-webfont.woff2") format("woff2"),
         url("../fonts/lora-regular-webfont.woff") format("woff"),
         url("../fonts/lora-regular-webfont.ttf") format("truetype"),
         url("../fonts/lora-regular-webfont.svg#loraregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
