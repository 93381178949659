.droptab-selector{
  .selectpicker{
    display: none;
  }

  .bootstrap-select{
    width: 100%;

    .dropdown-toggle{
      border: none;
    }

    .dropdown-menu{
      &.inner{
        border-color: $color14;
        padding: 0px;
      }
      >li{
        padding: 5px 0px;

        &:hover{
          background-color: $color2;
          >a{
            color: $color7;
            background-color: $color2;
          }
        }
      }
    }

    .bs-caret{
      height: 50px;
      width: 50px;
      right: 0;
      top: 0;
      background-color: $color14;
      transform: none;

      &:before{
        top: 50%;
        left: 50%;
        width: 18px;
        height: 10px;
        content: " ";
        position: absolute;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(-50%) translateY(-50%);
        background-image: url("../images/downarrow.png");
      }
    }
    .caret{
      display: none;
    }
  }
}