.wp_video{
  background-color: $color10;
  padding: 30px 0 50px 0;
  h2{
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center;
  }
  .video_data{
    width: 100%;
    max-width: 472px;
    height: auto;
    overflow: visible;
    position: relative;
    margin: auto;
    border: 2px $color19 solid;
    @include media(">=small") {
      width: 472px;
    }
    @include media(">=medium") {
      width: 910px;
      max-width: 910px;
    }
    &:hover{
      cursor: pointer;
      .play_bottom{
        //display: block;
        background-color: #e52d27;
      }
    }
    .hover_video_overlay{
      height: 300px;;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0px;
      left: 0;
      z-index: 1;
      background-color: $color19;
      opacity: 0.6;
      @include media(">=small") {
        height: 450px;
      }
    }
    .play_bottom{
      width: 70px;
      height: 50px;
      position: absolute;
      margin: auto;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      z-index: 2;
      right: 0;
      top: 124px;
      left: 0;
      @include media(">=small") {
        top: 200px;
      }
    }
    .arrow-right {
      z-index: 2;
      margin: auto;
      margin-top: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 22px;
      border-color: transparent transparent transparent #fff;
      @include media(">=small") {
        top: 200px;
      }
    }
    .thumbnail_video{
      width: 100%;
      background-size: cover;
      background-repeat: none;
      background-position: center;
      height: 300px;
      position: relative;
      @include media(">=small") {
        height: 450px;
      }
    }
    .duration{
      position: absolute;
      bottom: 8px;
      right: 8px;
      background-color: $color19;
      color: $color7;
      padding: 2px 5px;
      margin: 0;
      z-index: 2;
    }
  }
  .modal-dialog{
    width: 90%;
    height: 60%;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    @include media(">=small") {
      width: 90%;
      height: 70%;
    }
    @include media(">=medium") {
      width: 65%;
    }
    .modal-content{
      height: 100%;
      .modal-body{
        height: 100%;
        padding: 0;
        .close {
          top: -28px;
          right: -27px;
          font-size: 35px;
          position: absolute;
          border-radius: 20px;
          width: 44px;
          font-size: 35px;
          opacity: 1;
          color: #fff;
        }
      }
    }
  }
}