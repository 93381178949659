#footer {
  $headingPadding: 18px 0;
  $bgFile: "../images/footer-border.png";
  background-color: $color-footer-bg;
  color: $color-footer-fg;
  min-height: 360px;
  margin: 0;
  background-image: url($bgFile),url($bgFile);
  background-position: left top, left bottom;
  background-repeat: repeat-x;
  padding: 56px 0;

  a:not(.btn) {
    color: $color-footer-fg;
    font-size: 14px;

    &:focus,
    &:hover {
      opacity: 0.5;
    }

    &:link, &:visited, &:active, &:hover {
      text-decoration: none;
    }
  }
  .expanded > a {
    font-family: $font1-bold;
    text-transform: uppercase;
    padding: $headingPadding;
    .caret {
      display: none;
    }
  }
  .expanded ul {
    margin-left: 0;
  }
  .leaf a {
    padding: 2px 0;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
  }
  .contact {
    padding-left: 0;
    a {
      display: inline-block;
    }
    & > ul {
      padding: 0;
    }
    li {
      list-style: none;
    }
    ul li {
      position: relative;
      padding-left: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .icon:before {
      position: absolute;
      top: -6px;
      left:0;
      font-size: 20px;
    }
  }

  .follow-info {
    margin-top: 20px;
    .btn {
      width: 160px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .follow {
      padding-bottom: 20px;

      .heading {
        text-transform: uppercase;
        font-family: $font1-bold;
        padding: $headingPadding;
        display: inline-block;
      }
      a {
        display: inline-block;
        letter-spacing: 15px;
        font-size: 22px;
      }
    }
  }

  @include media(">=medium") {
    .contact {
      ul li {
        padding-left: $grid-gutter-width;
      }
    }

    .follow-info {
      .follow {
        a {
          letter-spacing: 20px;
        }
      }
    }
  }
}
