.card {
  $card-title-family: $font2-bold;

  background-color: $color7;

  .info {
    font-size: $card-copy-size;
  }

  .see-more {
    width: $see-more-btn-width;
  }

  .title {
    font-family: $card-title-family;
    font-size: $card-title-size;
  }
}
