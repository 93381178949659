.wp-static-content {
  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 24px;
    margin: 0 0 30px 0;
  }

  h2 {
    font-family: $font2-bold;
    font-size: 18px;
  }

  h4 {
    color: $color1;
    font-family: $font1;
    font-size: 14px;
    margin-top: $gutter-with-sm;
  }
}
