.loader {
  font-size: 25px;
  display: none;
  color: $color9;

  &.big{
    font-size: 40px;
  }
}

.loader .image{
  animation: spin 1.5s infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}