h2 {
  @include media("<small") {
    margin: 30px 0px 30px 0px;
    font-size: 23px;
  }
}
.block-ati-map {
  $animationTime: 0.3s;
  width: 100%;
  text-align: center;
  position: relative;
  background-color: $color6;
  h2 {
    margin-bottom: 30px;
    margin-top: 0;
    padding-top: 30px;
  }
  .vmap-wrapper {
    text-align: center;
    display: none;
  }
  & > .container {
    overflow: visible;
  }
  .vmap {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 778px;
    &:before {
    	content: "";
    	display: block;
    	padding-top: 100%;
    }
  }
  .vmap-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 778px;
    top: 0;
  	left: 0;
  	bottom: 0;
  	right: 0;
  }
  path {
    fill: $color-map-fg;
    stroke: $color-map-stroke;
    &.enabled {
      fill: $color-map-region;
      cursor: pointer;
    }
    &.selected {
      fill: $color-map-hover;
    }
  }
  g {
    &.enabled path {
      fill: $color-map-region;
      cursor: pointer;
    }
    &.selected path {
      fill: $color-map-hover;
    }
  }
  text {
    cursor: pointer;
    font-family:$font2;
    font-size: 10px;
  }
  .map-tooltip {
    $size: 60px;
    $distance: 10%;
    overflow: visible;
    z-index: $index1;
    height: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity $animationTime;
    display: none;
    &.show {
      opacity: 1;
      transition: opacity $animationTime;
    }
    .tooltip-content {
      right: -$distance;
      width: $size;
      height: $size;
      border-radius: $size / 2;
      position: relative;
      line-height: normal;
      background-color: $color7;
      border: 2px solid rgba(black, 0.3);
      padding: 2px;
      figure {
        width: 100%;
        height: 100%;
        border-radius: $size / 2;
        background-position: center;
        background-size: cover;
      }
    }
    .tooltip-arrow {
      width: $distance;
      height: 1px;
      background-color: rgba($color4, 0.2);
      border: 0;
      top: $size / 2;
    }
  }
  .region-details {
    opacity: 0;
    transition: opacity $animationTime;
    text-align: left;
    &.show {
      opacity: 1;
      transition: opacity $animationTime;
    }
    .title {
      display: inline-block;
      color: $color-font-heading;
      font-family: $font2;
      font-size: 22px;
      margin-bottom: 26px;
    }
    p {
      font-family: $font1;
      font-size: 14px;
      opacity: 0.9;
      text-align: left;
      margin-bottom: 16px;
    }
    .btn {
      min-width: 190px;
    }
  }

  .details {
    margin-bottom: 10px;
    margin-top: 25px;
    .detail {
      float: left;
      min-width: 65px;
      text-align: center;
      position: relative;
      &:first-child {
        .separator {
          display: none;
        }
      }
    }
    .detail-image {
      display: block;
      margin: 0 auto;
      width: $card-icon-width;
      height: $card-icon-height;
      margin-bottom: 10px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .detail-copy {
      margin-bottom: 0px;
      font-size: 10px;
      text-align: center;
    }
    .separator {
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 30px;
      background-color: $color5;
    }
  }
  .map_carousel {
    padding-bottom: 30px;
    @include media(">=small") {
      display: none;
    }
  }
  .country_info {
    width: 96%;
    height: 100%;
    background-color: $color7;
    text-align: left;
    overflow: auto;
    z-index: 1;
    margin: auto;
    img {
      max-height: 210px;
      width: 100%;
    }
    >div {
      width: 90%;
      margin: auto;
    }
    h2 {
      font-family: $font2-bold;
      font-size: 18px;
      margin: 20px 0 10px 0;
    }
    p {
      font-family: $font1;
      font-size: 14.2px;
    }
    .details {
      margin-top: 40px;
    }
    a {
      background-color: $color1;
      font-family: $font1-bold;
      color: $color7;
      text-align: center;
      padding: 10px 0px;
      width: 100%;
      display: block;
      margin: 15px auto 15px auto;
      cursor: pointer;
      width: 100%;
      &:hover {
        text-decoration: none;
        background-color: $color3;
      }
    }
  }

  @include media(">=small") {
    display: block;
    background-color: $color7;
    padding: 60px 0;
    .map-tooltip {
        $size: 120px;
        $distance: 14%;
      .tooltip-content {
        right: -$distance;
        width: $size;
        height: $size;
        border-radius: $size / 2;
        padding: 4px;
        figure {
          border-radius: $size / 2;
        }
      }
      .tooltip-arrow {
        width: $distance;
        top: $size / 2;
      }
    }
    .container {
      position: relative;
    }
    .vmap-wrapper {
      text-align: right;
      display: block;
    }
    .region-details {
      position: absolute;
      left: 0;
      top: 58%;
    }
  }
  @include media(">=large") {
    .vmap-wrapper {
      text-align: center;
    }
  }
}
