.wp_webinars{
  height: auto;
  overflow: visible;
  h2{
    margin-top: 0;
  }
  .search_webinars{
    position:relative;
    width: 100%;
    margin-bottom: 30px;
    @include media(">=small") {
      width: 311px;
    }
    .textinput{
      width: 100%;
      height: 30px;
      padding-left: 14px;
      padding-right: 30px;
      border: solid 1px #9A8C7E;
    }
    .icon-search{
      font-size: 14px;
      position: absolute;
      right: 9px;
      top: 6px;
    }
  }
  .video_ovelay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
    background-color: $color19;
    opacity: 0.6;
  }
  .video_content{
    position: fixed;
    width: 90%;
    height: 60%;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 12;
    @include media(">=small") {
      width: 90%;
      height: 70%;
    }
    @include media(">=medium") {
      width: 65%;
    }
  }
}