// Override Bootstrap's pager styles

.pager {
    li > a {
      border: none;
      color: $color1;
      text-decoration: underline;
      text-transform: capitalize;

      &:hover,
      &:focus {
        background-color: transparent;
        color: $color3;
        text-decoration: underline;
      }
    }

    li.pager-current > span {
      background-color: transparent;
      border: none;
      color: $color3;

      &:hover,
      &:focus {
        background-color: transparent;
        border: none;
        color: $color3;
      }
    }
}
