.newsletter{

  h2{
    display: none;

    &.heading{
      font-size: 24px;
      text-align: center;
      margin-top: 10px;
      display: block;
    }
  }


  .note{
    text-align: center;
    margin-bottom: 15px;
  }

  .modal-body{
    padding: 30px;
  }

  @include media(">=small") {
    .modal-dialog{
      width: 535px;
    }
  }

  @include media("<=extraSmall") {
    .ati-form {
      .checkbox .checkbox-label {
        padding: 0px;
        display: block;

        @include media("<small") {
          display: inline-block;
          font-size: 13px;
        }

      }
    }
  }

}