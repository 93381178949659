.video_info{
  width: 45%;
  float: left;
  margin: 0 5% 0 0;
  height: auto;
  overflow: visible;
  position: relative;
  @include media(">=medium") {
    width: 31%;
    margin:0 2% 0 0;
  }
  &:hover{
    .hover_video_overlay, .hover_play_bottom{
      display: block;
    }
  }
  .hover_video_overlay{
    height: 155px;;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0px;
    left: 0;
    z-index: 1;
    display: none;
    background-color: $color19;
    opacity: 0.6;
  }
  .hover_play_bottom{
    width: 82px;
    height: 40px;
    position: absolute;
    margin: auto;
    border: 1px solid $color7;
    display: none;
    z-index: 2;
    right: 0;
    top: 55px;
    left: 0;
    background: url("/sites/all/themes/atravel_theme/dist/images/play_video.png") no-repeat center;
  }
  .thumbnail_video{
    width: 100%;
    background-size: cover;
    background-repeat: none;
    background-position: center;
    height: 155px;
    position: relative;
  }
  .duration{
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: $color19;
    color: $color7;
    padding: 2px 5px;
    margin: 0;
  }
  .title{
    color: $color8;
    font-family: $font1-semibold;
    margin: 10px 0 0 0;
    min-height: 44px;
  }
}
