.wp-sections {
  $padding-component: 60px;
  $padding-section: $grid-gutter-width;
  $right-box-width: 472px;
  $middle-box-width: 472px;
  $small-box-width: 228px;
  $margin-section: $gutter-with-sm;

  border-bottom: 1px solid $color11;
  padding: $padding-component 0;

  h2{
    text-align: center;
    margin: 0 0 50px 0px;
  }
  p {
    font-family: $font1-light;
    font-size: 14px;
  }

  span {
    font-size: 24px;
  }

  .button-5 {
    width: 160px;
  }

  .column {
    width: 100%;

    &:first-child {
      margin-bottom: $margin-section;
    }
  }

  .sections {
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  .middle-wrapper{
    margin-bottom: $margin-section;
    max-width: $middle-box-width;
    margin: auto;
  }

  .section {
    width: 100%;
    color: $color7;
    display: table;
    margin: auto;
    padding: $padding-section;
    text-align: left;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .inner {
      display: table-cell;
      vertical-align: bottom;
    }
    span{
      text-shadow: -3px 2px 1px #000;
    }
    &.bottom-left {
      margin-right: 3%;
    }

    &.bottom-left,
    &.bottom-middle {
      height: 150px;
      max-width: $small-box-width;
      width: 48%;
      display: table;
      float: left;

      .inner{
        display: table-cell;
        vertical-align: bottom;
      }

      span {
        font-size: 14px;
      }
    }

    &.bottom-right {
      height: 243px;
      max-width: $right-box-width;
    }

    &.top-left {
      height: 298px;
      margin-bottom: $margin-section;
      max-width: 470px;
    }

    &.top-right {
      height: 205px;
      margin-bottom: $margin-section;
      max-width: $right-box-width;
    }

    &.footer-section{
      height: 205px;
      margin-top: $margin-section;
      max-width: $right-box-width;
    }
  }

  @include media(">=extraSmall") {
    .section {
      &.bottom-left {
        margin-right: 3.4%;
      }
    }
  }

  @include media(">=small") {
    .column {
      margin: auto;
      width: 470px;
    }

    .section {
      &.bottom-left {
        margin-right: 4%;
      }

      &.bottom-left {
        margin-bottom: 0;
      }

      &.bottom-left,
      &.bottom-middle {
        height: 150px;
        float: left;
        max-width: $small-box-width;
      }
    }
  }

  @include media(">=medium") {
    .column {
      width: 440px;
      display: inline-block;

      &:first-child {
        margin-bottom: 0;
        margin-right: $padding-section;
      }
    }



    .section {
      &.top-left {
       // margin-right: 0;
      }

      &.top-right,
      &.bottom-right {
        margin-left: 0;
      }
    }
  }

  @include media(">=large") {
    .column {
      margin-bottom: 0;
      float: none;
    }
  }
}
