
.fixed_body{
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}
#header_mobile{
  position: relative;
  overflow: visible;
  .top_menu_mobile{
    display: block;
    position: absolute;
    top: 20px;
    z-index: 1;
    text-align: center;
    width: 100%;
    overflow: visible;
    >img{
      &:first-of-type{
        float: left;
        padding: 10px;
        margin: -10px;
      }
      &:last-of-type{
        width: 25px;
        float: right;
        top: -5px;
        position: relative;
      }
    }
    >a{
      img{
        width: 172px;
        position: relative;
        top: -18px;
      }
    }
  }
  @include media(">=small"){
    display: none;
  }
  .translate_menu_open{
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    -ms-transform: translateX(100%);
  }
  .translate_menu_close{
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
  }
  .overlay{
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 7;
    top: 0;
    bottom: 0;
    position: fixed;
    opacity: 0;
    display: none;
  }

  .displayOverlay{
    -webkit-animation: OPACITY-OVERLAY 1s;
    -moz-animation:    OPACITY-OVERLAY 1s;
    -o-animation:      OPACITY-OVERLAY 1s;
    animation:         OPACITY-OVERLAY 1s;
    display: block;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @-webkit-keyframes OPACITY-OVERLAY {
    0%   { opacity: 0; }
    100% { opacity: 0.6; }
  }
  @-moz-keyframes OPACITY-OVERLAY {
    0%   { opacity: 0; }
    100% { opacity: 0.6; }
  }
  @-o-keyframes OPACITY-OVERLAY {
    0%   { opacity: 0; }
    100% { opacity: 0.6; }
  }
  @keyframes OPACITY-OVERLAY {
    0%   { opacity: 0; }
    100% { opacity: 0.6; }
  }
  .search_mobile{
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 10;
    top: 15px;
    input{
      height: 51px;
      width: 90%;
      font-size: 17px;
      padding-left: 10px;
      border: none;
    }
    span{
      position: absolute;
      left: 85%;
      top: 15px;
    }
  }
  .home-link{
    display: block;
    text-align: left;
    padding-left: 20px;
  }
  .menu_mobile{
    position: fixed;
    width: 80%;
    top: 2px;
    bottom: 0;
    left: -80%;
    z-index: $index6;
     //position: fixed;
    height: 100%;
    background-color: $color7;
    text-align: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    ul{
      padding: 0;
    }
    li{
      list-style: none;
      font-family: $font1;
      text-align: left;
      a{
        color: $color3;
        display: block;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .site-logo{
      margin-right: 10px;
    }
    img{
      padding: 20px 0;
    }
    .main_menu_mobile{
      li{
        padding: 15px 20px;
        border-bottom: 1px solid $color23;
        font-size: 18px;
        &:first-of-type{
          border-top: 1px solid $color23;

        }
      }
      .dropdown-menu{
        //  display: none;
          position: relative;
          float: none;
          border: none;
          li{
            border: none;
            padding: 10px 10px;
          }
          a{
            font-size: 16px;
            color: $color3;
            opacity: 0.9;
            padding: 0;
          }
        }
    }
    .secondary_menu_mobile{
      li{
        padding: 10px 20px;
        font-size: 16px;
        opacity: 0.9;
        &.ati-number{
          display: none;
        }
      }
    }
    .main_menu_mobile,
    .secondary_menu_mobile{
      >.active{
        border-left: 5px solid $color14;
        padding-left: 15px;
      }
    }
    .caret{
      display: none;
    }
  }
}

