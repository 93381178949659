.block-agent-login {
  width: 290px;
  margin: 60px auto;
  h1 {
    font-size: 24px;
    margin-bottom: 28px;
  }
  input:not([type="submit"]) {
    height: 30px;
  }
  input[type="submit"] {
    margin-right: 10px;
  }
  .btn {
    width: 138px;
    height: 40px;
    display: inline-block;
  }
  label {
    font-size: 12px;
  }
  form .form-actions .btn.register {
    margin-right: 0;
    clear: none;
  }
  .form-actions {
    margin-top: 29px;
    margin-bottom: 0;
  }
  @include media(">=small") {
    width: 310px;
    input[type="submit"] {
      margin-right: 10px;
    }
  }
}
