/* Three columns stacked layout */

#about-african-travel {
  $cols: 3;
  $spacing: $gutter-with-sm;

  .center-wrapper {
    overflow: hidden;
    padding: $grid-gutter-width 0 60px 0;
  }

  .panel-col,
  .panel-col-bottom,
  .panel-col-first,
  .panel-col-last {
    padding: 0 $spacing;
    width: 100%;

    .inside {
      margin: 0;
    }
  }

  .panel-col {
    margin-bottom: 60px;
  }

  .panel-col-bottom {
    margin-bottom: 85px;
  }

  .panel-col-last {
    img {
      display: inline-block;
      float: left;
    }

    p {
      margin: 0;
    }

    p:not(:last-child) {
      > img {
        margin-bottom: $spacing;
      }
    }

    p:nth-child(odd) {
      > img {
        margin-right: $spacing;
      }
    }
  }

  .quote {
    border-bottom: 1px solid $color11;
  }

  .wp-copy {
    padding: $grid-gutter-width 25px 5px 25px;
  }

  .wp-static-content {
    .wrapper {
      overflow: hidden;
    }

    h1 {
      text-align: center;
    }

    p {
      color: $color20;
    }
  }

  @include media(">=small") {
    .panel-col-last {
      .wp-static-content {
        .wrapper {
          margin: auto;
          width: 480px;
        }
      }
    }
  }

  @include media(">=small", "<medium") {
    .panel-col-first {
      text-align: center;
    }
  }

  @include media(">=medium") {
    .panel-col,
    .panel-col-first,
    .panel-col-last {
      width: 33.3%;
    }

    .panel-col {
      margin-bottom: 0;
    }

    .panel-col-bottom {
      margin-left: calc(100% / #{$cols});
      width: 70%;
    }

    .panel-col-first {
      padding-left: 10%;
    }

    .panel-col-last {
      img {
        float: none;
      }
    }

    .wp-static-content {
      .wrapper {
        width: 75%;
      }

      h1 {
        text-align: left;
      }
    }
  }

  @include media(">=large") {
    .panel-col-bottom {
      margin-left: calc(100% / #{$cols});
      width: 62%;
    }

    .panel-col-first {
      padding-left: 15%;
    }
  }
}
