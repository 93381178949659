.tour-details-itinerary{
  padding-top: 60px;

  .itinerary{
    @include custom-container();
  }

  .place{
    position: relative;

    .title{
      color: $color17;
      font-size: 17px;
      margin-bottom: 20px;
      font-family: $font1-bold;
    }

    .description{
      font-size: 17px;
      margin-bottom: 10px;
    }

    .accomodation{
      color: $color5;
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px;
      font-family: $font1-semibold;
    }

    .icon{
      left: 0;
      top: 0;
      color: $color1;
      position: absolute;
    }

    .image{
      height: 240px;
      margin: 0px 10px;
      background-size: cover;
      background-position: center;

      &:focus,
      &:active,
      &:hover{
        outline: none;
      }
    }

    .day{
      top: -4px;
      left: 17px;
      width: 30px;
      height: 30px;
      display: none;
      z-index: $index2;
      text-align: center;
      position: absolute;
      border-radius: 50%;
      padding: 4px 0px 0px 1px;
      font-family: $font1-bold;
      border: 2px solid $color1;
      background-color: $color7;
    }

    .line{
      top: 0;
      width: 2px;
      left: 31px;
      height: 110%;
      display: none;
      z-index: $index1;
      position: absolute;
      background-color: $color1;
    }
  }
  .itinerary-hero{
    width: 100%;
  }

  @include media(">=small") {
    .place{
      padding-left: 70px;

      .title{
        font-size: 16px;
        color: $color19;
      }

      .description{
        font-size: 14px;
      }

      .day-count{
        display: none;
      }

      .day, .line{
        display: block;
      }

      .image{
        height: 180px;
        margin: 0px 0px 25px 0px;
        display: inline-block;
        width: calc((100%/3) - 25px);
        &.separate-image{
          margin-right: 25px;
        }
      }
    }
  }
  @include media(">=medium") {
    .place{
      .image{
        height: 250px;
      }
    }
  }
}