.wp-cardcontainer{
  background-color: $color6;
  //border-top: 1px solid $color11;
  border-bottom: 1px solid $color11;

  .cards-container{
    padding: 50px 15px;
  }

  .title{
    margin: 0 0 50px 0px;
  }

  .cards{
    text-align: center;
    .tour{
      margin-bottom: 15px;
    }
  }

  .loader{
    display: none;
  }

  .btn.pagination-end{
    display: none;
  }

  .loading{
    .loader{
      left: 50%;
      bottom: 15px;
      display: block;
      position: absolute;
      transform: translateX(-50%);
    }
    .load-more{
      visibility: hidden;
    }
  }

  @include media(">=medium") {
    .cards{
      .tour:nth-child(odd){
        margin-right: 15px;
      }
    }
  }

  @include media(">=small") {
    .cards{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}