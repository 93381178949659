/*
 * These styles apply for the Agent News and Agent Press Releases pages.
 */

.agent-news-related{
  .title{
    font-size: 19px;
    text-align: center;
    margin: 0px 0px 15px 0px;
  }

  .views-row{
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color18;

    &:last-child{
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  @include media(">=small") {
    .title{
      font-size: 24px;
      text-align: left;
    }
  }
}