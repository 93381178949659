.agent-news{
  .heading{
    font-size: 17px;
    margin: 0px 0px 5px 0px;
    font-family: $font1-bold;
  }

  .date{
    font-size: 17px;
    font-family: $font1-lightit;
  }

  .content{
    font-size: 17px;
    margin-top: 25px;
    strong{
      font-family: $font1-semibold;
    }
  }

  @include media(">=small") {
    .heading,
    .date,
    .content{
      font-size: 14px;
    }
  }
}