.wp_destinations {
  .container {
    position: relative;
    overflow: visible;
  }

  span,
  p {
    font-family: $font1;
    font-size: 14.2px;
  }

  h2,
  h3 {
    font-family: $font2;
    color: $color3;
    font-size: 23px;
  }

  h3 {
    font-size: 20.5px;
    margin: $grid-gutter-width 0;
  }

  /***********why section************/
  .why_text {
    background-color: rgba($color7, 0.85);
    box-sizing: border-box;
    height: auto;
    padding: 0;
    position: static;
    width: 100%;

    h2 {
      margin: 30px 0px 30px 0px;
    }
  }
  .description{
    margin: 50px 0 0 0;
  }

  /***********quick info************/
  .quick_info {
    .type{
      position: relative;
      padding-left: 50px;
      margin: 20px 0px 0px 0px;

      &:last-child{
        margin-bottom: 20px;
      }

      .category{
        font-family: $font1-semibold;
      }
      img{
        position: absolute;
        font-size: 18px;
        left: 10px;
        top: 0px;
        width: 20px;
        height: 20px;
      }
    }
  }

  /***********Map************/
  .map_img {
    width: 100%;
    text-align: center;
    img{
      width: 100%;
    }
  }

  /***********tours section************/
  .tours {
    background-color: $color6;
    overflow: auto;

    .tour {
      width: 100%;
      max-width: 100%;
      margin-top: 30px;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin: 30px 0 60px 0;

        @include media("<small") {
          margin: 30px 0px 30px 0px;
        }
      }

      @media (max-width: 769px) {
          width: 100%;
      }
    }
  }

  /***********weather section************/
  .weather_content {
    display: table;
    width: 100%;
    margin: 60px 0px;

    .temperature,
    .rainfall {
      height: 65px;
    }

    .month {
      span {
       font-family: $font1-bold;
      }
    }

    .temperature {
      span {
        border-bottom: 1px solid $color11;
      }
    }

    >div {
      display: table-row;

      >:nth-child(2) {
        font-family: $font1-bold;
      }

      >span,
      img {
        font-family:$font1;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .weather_content_mobile {
    display: table;
    width: 70%;
    margin: 0 auto 30px auto;
    display: none;

    div {
      display: table-row;
      width: 100%;

      >span {
        display: table-cell;
        width: 33%;
        text-align: center;
        border-bottom: 1px solid $color11;
        padding: 10px 0;
      }

      img {
        padding-right: 3px;
      }

      &:last-of-type {
        span {
          border: none;
        }
      }

      .month {
        font-family: $font1-bold;
      }
    }

    span {
      font-family:$font1;
    }
  }

  @include media(">=small") {
    .quick_info {

    }

    .tours {
      h3 {
        margin-bottom: 50px;
      }
    }

    .why_text {
      height: 190px;
      padding: 28px;
      position: absolute;
      top: -240px;
      width: 470px;
      overflow: hidden;
      h2 {
        margin: 0px 0px 10px 0px;
      }
    }

    h3 {
      margin: 60px 0 0 0;
    }
  }
}
