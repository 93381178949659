.wp_special_interest_groups{
  margin-bottom: 30px;
  color:$color20;
  h2{
    font-family: $font1-bold;
    color: $color3;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 25px;
  }
  .main_content{
    width: 80%;
    li{
      margin-top: 20px;
    }
    h3{
      font-family: $font1-semibold;
      font-size: 14px;
    }
  }
  .card_interest_group{
    padding: 15px 0 30px 0px;
    display: table;
    .image{
      display: block;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 290px;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include media(">=small") {
        width: 40%;
        display: table-cell;
      }
    }

    .content{
      display: block;
      width: 100%;
      background-color: $color7;
      padding: 30px 20px;
      min-height: 290px;
      height: 100%;
      @include media(">=small") {
        width: 60%;
        display: table-cell;
      }
      .title{
        color: $color3;
        font-family: $font2-bold;
        font-size: 18px;
      }
      .description{
        color: $color3;
        margin: 30px 0;
      }
      .btn{
        width: 100%;
        @include media(">=small") {
          width: 175px;
        }
      }
    }
  }
}