/* Styles very specific for certain webform */

.co-brand-form {
  .btn.webform-submit {
    margin-right: $gutter-with-sm;
  }

  .form-actions {
    margin: 0;
  }

  .form-item {
    margin-bottom: $grid-gutter-width;
  }

  .form-managed-file {
    .form-submit {
      margin: $gutter-with-sm 0 0 0;
    }
  }

  .logo-field-container {
    input[type="file"] {
      width: 200px;
    }

    .description {
      visibility: hidden;
    }
  }

  .description.submit-description {
    display: inline-block;
    margin: 0;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .webform-component--co-brand-code {
    margin-bottom: $gutter-with-sm;
  }

  .webform-component--co-brand-description {
    p:last-child {
      margin: 0;
    }
  }

  .webform-component--submit-description {
    display: none;
  }

  @include media(">=medium") {
    .form-managed-file {
      .form-submit {
        margin: 0;
      }
    }
  }
}
