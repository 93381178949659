.tour-details-accomodations{
  @include custom-container();
  padding-top: 60px;
  padding-bottom: 60px;

  .accomodation{
    margin-top: 25px;

    &:first-child{
      margin-top: 0px;
    }

    .title{
      font-size: 16px;
      color: $color19;
      margin-bottom: 10px;
      font-family: $font1-bold;
    }

    .pin{
      color: $color5;
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px;
      font-family: $font1-semibold;
    }

    .icon{
      left: 0;
      top: 0;
      color: $color1;
      position: absolute;
    }

    .content-section{
      &:last-child{
        min-height: 0px;
      }
      min-height: 360px;
    }

    .main-image{
      margin-bottom: 15px;
    }

    .image{
      background-size: cover;
      background-position: center;

      &:focus,
      &:active,
      &:hover{
        outline: none;
      }
    }

    .secondary-images{
      margin-bottom: 20px;
    }

    .secondary-image{
      height: 115px;
      display: inline-block;
      width: calc((100%/3) - 12px);
      margin-right: 15px;
      &:last-child{
        margin-right: 0px;
      }
    }

    .slick-container{
      position: relative;
    }

    .slick-handler{
      top: 50%;
      height: 40px;
      width: 50px;
      display: none;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .prev-slick{
      left: 0;
      background-image: url("../images/left-arrow.png");
    }

    .next-slick{
      right: 0;
      background-image: url("../images/right-arrow.png");
    }

    .slick-sync-main{
      position: relative;
      .image{
        height: 400px;
      }
    }

    .slick-sync-nav{
      display: none;
      .image{
        width: 115px;
        height: 115px;
        margin: 0px 10px;
      }
    }

    .modal{
      .modal-content{
        background-color: transparent;
        margin: 0px 10px;
        border: none;
      }

      .modal-header{
        border: none;
      }

      .modal-body{
        border: none;
        padding: 0px;
      }

      .close{
        text-shadow: none;
        opacity: 1;
        color: $color7;
        font-size: 60px;
        right: 0;
        top: -14px;
        z-index: $index6;
      }
    }
  }

  @include media(">=small") {
    .accomodation{
      .content{
        display: flex;
        justify-content: space-between;
      }

      .content-section{
        &:last-child{
          min-height: 360px;
        }
        &.no-main{
          min-height: 0px;
          width: 100%;
        }
      }

      .modal{
        .close{
          top: 15px;
          right: 25px;
        }

        .modal-content{
          margin: 0px 90px;
        }

        .modal-dialog{
          width: 90%;
        }
      }

      .main-image{
        margin-bottom: 0px;
      }

      .content-section{
        width: 49%;
      }

      .slick-sync-main{
        padding: 0px 80px;
      }

      .slick-sync-nav{
        display: block;
      }

      .slick-handler{
        display: block;
      }
    }
  }
}