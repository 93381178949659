// Layout
// --------------------------------------------------
.container {
  .container,
  .container-fluid {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}
