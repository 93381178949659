.about-travel-corporation {
  .map {
    height: auto;
    width: 100%;
  }

  .wp-static-content {
    margin-bottom: 60px;

    .rtecenter {
      img {
        display: block;
        margin: auto;
      }

      img:first-child {
        margin-bottom: $grid-gutter-width;
      }
    }

    h1 {
      margin-bottom: 65px;
    }

    h4 {
      margin-bottom: $grid-gutter-width;
    }

    p:not(:last-child) {
      margin-bottom: $grid-gutter-width;
    }
  }

  @include media(">=small") {
    .wp-static-content {
      .rtecenter {
        img {
          display: inline-block;
        }

        img:first-child {
          margin-bottom: 0;
          margin-right: 90px;
        }
      }
    }
  }
}
