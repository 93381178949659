.wp-copy {
  overflow: hidden;
  padding: 60px 25px;

  $copy-cols: 3;
  $padding-button: 63px;

  .btn {
    padding-left: $padding-button;
    padding-right: $padding-button;
  }

  .copy-description {
    display: block;
    font-size: 18px;
    padding-bottom: 25px;
  }

  .copy-heading {
    color: $color3;
    display: block;
    font-family: $font2;
    font-size: 24px;
    padding-bottom: 15px;
  }

  .copy-wrapper {
    float: none;
    text-align: center;
    width: 100%;

    @include media(">=small") {
      width: calc(100% / #{$copy-cols});

      &.copy-center {
        margin: auto;
      }

      &.copy-left {
        float: left;
      }

      &.copy-right {
        float: right;
      }
    }
  }
}
