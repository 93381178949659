.ribbon{
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 60px 60px 0;
  color: $color7;

  &.special{
    border-color: transparent $color13 transparent transparent;
  }

  &.add-on{
    border-color: transparent $color1 transparent transparent;
  }

  .ribbon-section{
    position: absolute;
    display: block;
    font-size: 12px;
    font-family: $font1-semibold;
  }

  .special-copy{
    transform: rotateZ(45Deg);
    top: 12px;
    right: -60px;
  }

  .special-icon{
    top: 6px;
    right: -54px;
    position: absolute;
  }
}