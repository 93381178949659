#countries_drop_down{
  position: static;
  margin: auto;
  width: 100%;

  @include media(">=small") {
    width: 62%;
  }
  @include media(">=medium") {
    width: 40%;
  }
  span, a{
    font-size: 17px;
  }
  li{
    border-bottom: 1px solid $color16;
    &:last-of-type{
      border: none;
    }
  }
  .bootstrap-select .dropdown-toggle{
    height: 50px;
  }

}
.dropdown_countries_homepage{
  #countries_drop_down{

    padding-top: 30px;
    width: 80%;
    .btn {
      border: 1px solid $color11;
    }
  }
}