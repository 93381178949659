.tour-details-reviews{
  @include custom-container();
  padding-top: 60px;
  padding-bottom: 60px;

  .review{
    .image{
      height: 75px;
      width: 75px;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }

    .personal-info{
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
    }

    .name{
      font-size: 16px;
      color: $color19;
      font-family: $font1-bold;
      margin-bottom: 8px;

      .date{
        font-family: $font1-light;
        font-size: 14px;
        margin: 0px;
      }
    }

    .rating{
      margin: 0px;
    }

    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid $color18;

    &:first-child{
      border: none;
      padding-top: 0px;
      margin-top: 0px;
    }
  }
}