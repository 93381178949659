#resources .panels-bootstrap-row-two_columns-main {
  background: $color10;
  padding: 30px 0px;
  @include media(">=small") {
    background:$color7;
    padding: 60px 0px;
  }
}
.wp_resources{
  h2{
    font-family: $font2;
    font-size: 22px;
    text-align: center;
    margin: 0;
    @include media(">=small") {
      font-size: 29px;
      text-align: left;
    }
  }
  .resource_content{
    border-bottom: 1px solid $color10;
    background-color: $color7;
    padding: 25px 20px;
    margin: 20px 0;
    @include media(">=small") {
      margin: 0;
      padding: 25px 0;
    }
    &:last-of-type{
      border: none;
    }
    .title{
      color: $color3;
      font-family: $font1-semibold;
    }
    strong{
      margin-bottom: -10px;
      display: block;
    }
    a{
      text-decoration: underline;
    }
  }
}
