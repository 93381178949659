#our-partners {
  .our-partners {
    h1 {
      margin-bottom: 65px;
    }

    hr {
      margin-bottom: 30px;
      margin-top: 30px;
      border-top: 1px solid #ccc;
    }

    img {
      margin-bottom: 15px;
      margin-right: 6%;
    }

    .wrapper {
      text-align: center;
    }
  }

  @include media(">=small") {
    .our-partners {
      img {
        margin-bottom: 0;
      }

      .wrapper {
        text-align: left;
      }
    }
  }

  @include media(">=large") {
    .our-partners {
      hr {
        margin-left: 0;
        width: 90%;
      }
    }
  }
}
