/** OVERRRIDE OF DEFAULTS FROM BOOTSTRAP SELECT PLUGIN **/

.bootstrap-select{
  min-width: 160px;
  font-family: $font1-light !important;
  letter-spacing: normal;
  &.open{
    .dropdown-toggle{
      color: rgba($color3, 0.6) !important;
      border-color: rgba($color3, 0.3) !important;
      background-color: $color7 !important;
    }
  }

  .bs-caret {
    position: absolute;
    right: 0;
    top: 25px;
    .caret{
      border-top: 5px solid;
    }
  }

  .dropdown-toggle{
    width: 100%;
    height: 50px;
    color: rgba($color3, 0.6);
    border-color: rgba($color3, 0.3);
    background-color: $color7;
    position: relative;
    padding: 10px 12px;
    font-size: 17px;
    font-family: $font1-light !important;

    &:active:hover,
    &:active:focus{
      color: $color16 !important;
      border-color: $color16 !important;
      background-color: $color7 !important;
    }

    &:focus{
      outline: none !important;
    }

    .filter-option{
      text-transform: none;
    }
  }

  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }

  &.btn-group .dropdown-menu.inner {
    position: relative;
    float: left;
  }

  .dropdown-menu{
    border: none;
    margin: 0;
    width: 100%;
    font-size: 17px;
    border-radius: 0px;
    color: $color16 !important;
    background-color: $color7 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    &.inner{
      border: 1px solid $color16 !important;
      margin: 0px;
    }

    &.open{
      padding: 0;
      border: 0;
      overflow: visible !important;
    }

    ul li a{
      color: $color16;
      padding: 3px 12px;
      cursor: pointer;
      white-space: normal;

      &:hover{
        background-color: $color2;
        color: $color7;
      }

      &:focus{
        outline: none;
      }

      span.text{
        margin-top: 7px;
        margin-bottom: 7px;
      }

      .glyphicon{
        display: none;
      }
    }
  }

  @include media(">=small") {
    .bs-caret{
      top: 18px;
    }
    .dropdown-toggle{
      height: 40px;
      font-size: 14px;
    }
    .dropdown-menu{
      font-size: 14px;
    }
  }
}
