.treadright-foundation {
  $button-width: 190px;

  .wp-static-content {
    .btn {
      margin: $grid-gutter-width 0;
      text-decoration: none;
      width: $button-width;
    }

    iframe {
      width: 100%;
    }
  }

  @include media(">=small") {
    .wp-static-content {
      iframe {
        width: 500px;
      }
    }
  }
}
