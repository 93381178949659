// Mixins
// -----------------------------------------------------------------------------
@mixin valign-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin custom-container(){
  margin: 0 auto;
  overflow: visible;
  padding: 0px 15px;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* Safari, Chrome and Opera */
  color: $color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  &::-ms-input-placeholder { /* Edge */
    color: $color;
  }
}
