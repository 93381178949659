#african-travel-cares {
  $content-width: 600px;
  $foundation-width: 717px;

  .foundation {
    margin-bottom: 30px;
    max-width: $foundation-width;

    figure {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .wp-static-content {
    margin-bottom: 35px;

    .wrapper {
      width: 100%;
    }
  }

  @include media(">=small") {
    .wp-static-content {
      .wrapper {
        max-width: $content-width;
      }
    }
  }
}

// African Travel Cares Side Menu
.menu-link-back {
  a:before {
    content: $arrow-back-content;
    left: 0;
    position: absolute;
  }
}
