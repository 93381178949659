.wp-hero {
  background-size: cover;
  background-position: center;
  display: table;
  padding: 35px;
  @include media(">=small") {
    padding: 60px;
  }
  width: 100%;
  position: relative;
  .hero-title{
    font-size: 24px;
    @include media(">=small") {
      font-size: 36px;
    }
  }
  .inner {
    display: table-cell;
  }
  .welcome-banner{
    color: $color7;
    font-family: $font2-bold;
    font-size: 36px;
  }
  &.valign-bottom-mobile > .inner {
    vertical-align: bottom;
  }
  &.valign-middle-mobile > .inner {
    vertical-align: middle;
  }
  &.valign-bottom > .inner {
    @include media(">=small") {
      vertical-align: bottom;
    }
  }
  &.valign-middle > .inner {
    @include media(">=small") {
      vertical-align: middle;
    }
  }
}
