.side-nav {
  .menu {
    a {
      color: $color3;
      padding-bottom: 0;
      padding-top: 0;
      margin-bottom: 25px;

      &:hover {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    li {
      &.active {
        font-weight: bold;
      }
    }
    @include media("<small"){
      display: none;
    }
  }
}

.wp-hero .tab-selector{
  position: absolute;
  top: 304px;
  width: 88%;
  left: 6%;
}
