.view-filters {
  line-height: 60px;

  $btn-filter-width: 160px;

  .filter-wrapper {
    line-height: normal;
    width: 100%;
    display: inline-block;
    letter-spacing: normal;
    display: block;
    @include media(">customTablet") {
      display: inline-block;
      width: $btn-filter-width;
    }
  }

  .filter-label {
    width: 80px;
    margin: 0;
  }

  .btn.button-carret {
    width: 100%;
    font-size: 17px;
    @include media(">=small") {
      font-size: 14px;
    }
  }
  .views-exposed-widgets {
    letter-spacing: normal;
  }
}
