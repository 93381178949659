#author-archive,
.author-archive,
.view-reviews-by-country {
  $photo-width: 60px;

  .by-author {
    display: none;
  }

  .panel-col-last {
    max-width: 800px;
  }

  h2 {
    font-size:  18px;
    font-family: $font2-bold;
  }

  .review {
    border-bottom: 1px solid $color11;
    padding-bottom: 10px;

    & > .author,
    .photo {
      padding: 0;
    }

    .photo--figure {
      background-position: center;
      background-size: cover;
      border-radius: $photo-width;
      height: $photo-width;
      margin-top: 20px;
      width: $photo-width;
    }

    .photo--figure.default {
      background-color: #f0f0f0;
    }

    @include media(">=small") {
      .photo + .author {
        padding-left: 35px;
      }

      & > div{
        padding: 0 0 15px 0;
      }
    }
  }

  .content-wrapper {
    width: 100%;

    p{
      img{
        margin: 5px 2px;
      }
    }
  }

  .date {
    margin-bottom: $grid-gutter-width;
  }

  .tags {
    padding: $gutter-with-sm 0;

    .tag {
      margin-bottom: 10px;
      display: inline-block;
      padding: 10px 20px;
      background-color: $color25;
      line-height: 10px;
      height: 30px;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: $gutter-with-sm;
      }

      a {
        color: $color20;
        font-size: 10px;
        opacity: 0.4;
      }
    }
  }
}

// Search by country or tag
.page-taxonomy,
.page-countries {
  .block-system {
    padding: 15px;
    @include media(">=small") {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px 0;
    }
  }
}
