.educational-trip {
  $btn-width: 170px;
  $image-height: 330px;
  $image-width: 309px;

  width: 100%;

  .additional-text {
    color: $color1;
    display: block;
    font-family: $font1-bold;
    font-size: 12px;
    padding-top: $gutter-with-sm;
  }

  .apply-now {
    width: $btn-width;
  }

  .content {
    padding: $grid-gutter-width $gutter-with-sm;
  }

  .description {
    margin: $grid-gutter-width 0;
  }

  .footer {
    text-align: center;
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: $image-height;
    padding: 0;
  }

  .packages {
    .package:not(:last-child) {
      margin-bottom: $gutter-with-sm;
    }
  }

  .title {
    color: $color3;
    margin-bottom: $gutter-with-sm;
    text-align: center;
  }

  @include media(">=small") {
    .additional-text {
      display: inline-block;
      padding-left: 20px;
      padding-top: 0;
    }
  }

  @include media(">=medium") {
    display: inline-flex;

    .content,
    .image {
      display: inline-block;
      float: left;
    }

    .content {
      width: calc(100% - #{$image-width});
    }

    .footer {
      text-align: left;
    }

    .image {
      width: $image-width;
    }

    .title {
      text-align: left;
    }
  }
}
