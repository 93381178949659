.award {
  $title-margin: $gutter-with-sm;
  $award-padding: $grid-gutter-width;

  padding: $award-padding;
  width: 100%;

  h3 {
    margin: 0 0 $title-margin 0;
  }

  .image {
    text-align: center;
    margin-bottom: 20px;
  }

  .year {
    display: block;
    font-family: $font1-bold;
    font-style: italic;
    margin-bottom: $grid-gutter-width;
  }
}
