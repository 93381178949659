// Header Layout
// -----------------------------------------------------------------------------
#header {
  position: relative;
  overflow: visible;
  display: none;

  @include media(">=small") {
    display: block;
  }

  @include media(">=small" and "<medium") {
    .container {
        width: 100%;
    }
  }

  a:not(.btn) {
    color: lighten($color-navbar-fg, 10%);
    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  .main-nav {
    background-color: transparent;
    border: 0;
    margin-bottom: 0;
  }

  .header-logo {
    padding-top: 12px;
    letter-spacing: 5px;
  }

  .wrapper-cobrand {
    display: inline-block;
    max-width: 48%;
    .logo {
      max-width: 100%;
    }
  }

  .top-nav {
    background-color: transparent;
    border: 0;
    #top-menu {
      width: 100%;

      li {
        width: 15%;
        &.co-branded-shown {
          width: auto;
          .custom-brand-header {
            padding: 20px 0px 25px 0px;
            line-height: 20px;
            >p {
              margin: 0;
            }
          }
        }
        @include media(">=medium") {
          width: 16%;
        }
        a {
          font-family: $font1;
          color: $color3;
          opacity: 0.6;
          text-align: center;
          font-size: 14px;
        }
        &.ati-number {
          width: 25%;
          @include media(">=medium") {
            width: 20%;
          }
          a {
            color: $color2;
            font-family: $font1-semibold;
            font-size: 20px;
            opacity: 1;
            text-align: center;
            span {
              font-size: 11px;
              display: block;
              line-height: 12px;
            }
          }
        }
      }
      li.active {
        >a {
          font-family: $font1-bold;
          opacity: 1;
        }
        background: none;
        border-bottom: 3px solid $color14;
      }
    }
  }

  .main-menu {
    position: relative;
  }

  .home-link {
    top: 16px;
    left: 35px;
    width: 25px;
    height: 23px;
    position: absolute;
    background-image: url("../images/home-ati.png");

    &.co-brand {
      left: 20px;
    }
  }

  .search-wrapper {
    width: 30%;
    .search-form {
      margin-left: 10px;
      font-size: 11px;
      input {
        padding-left: 4px;
      }
    }
    &.co-brand {
      width: 26%;
    }
    @include media(">=medium") {
      &:not(.co-brand) {
         .search-form {
          font-size: inherit;
          margin-left: 40px;
        }
      }
      &.co-brand {
        width: 30%;
      }
    }
  }

  #main_menu {
    width: 67%;
    >li {
      width: 25%;
      @include media(">=medium") {
        width: 25%;
      }
      >a {
        font-family: $font1;
        color: $color3;
        font-size: 15px;
        opacity: 0.9;
        text-align: center;
        @include media(">=medium") {
          font-size: 16px;
        }
      }
    }
    >li.active {
      >a {
        font-family: $font1-bold;
        background: none;
      }
      border-bottom: 3px solid $color14;
    }
    &.co-brand {
      width: 73%;
      >li {
        width: 32%;
        >a {
          font-size: 14px;
        }
      }
    }
    @include media(">=medium") {
      &.co-brand {
        width: 67%;
      }
    }
  }

  .container,
  .row { // Container hides dropdowns.
    overflow: visible;
  }

  .dropdown-menu {
    left: 0;
    right: auto;
    width: 246px;
    padding: 0;
    border-color: white;
    border-radius: 2px;
  }

  .dropdown-menu a {
    padding: 12px 20px;
    color: $color-font-body;
    background-color: $color-body-bg;
    &:hover {
      background-color: $color9;
      color: $color-body-bg;
    }
  }

  .dropdown-menu li:not(:first-child) {
    border-top: 1px solid $color10;
  }

  .navbar li.open > a {
    background-color: transparent;
  }

  a[href^=tel] {
    font-family: $font1-semibold;
  }

  .btn {
    margin-top: 10px;
    padding: 3px 14px;
    border-width: 1px;
  }

  ul li:not(:last-child) .btn {
    margin-right: 10px;
  }

  .button-2 {
    background-color: inherit !important;
  }

  .search-form {
    color: lighten($color-navbar-fg, 10%);
    margin: 0;
    margin-top: 13px;
    font-family: $font1;
    font-size: 12px;
    position: relative;
  }

  .search-field {
    width: 100%;
    height: 30px;
    @include placeholder-color(lighten($color-navbar-fg, 10%));
    padding-left: 14px;
    padding-right: 30px;
    font-size: 11px;
    border: solid 1px $color16;
  }

  .icon-search {
    font-size: 14px;
    position: absolute;
    right: 9px;
    top: 6px;
  }

  @include media(">=small") {
    #top-menu {
      .menu-link-a-day-on-safari {
        display: none;
      }
    }

    .divider {
      width: 92%;
      top: 67px;
      right: 0;
      position: absolute;
      margin: 0;
      @include media(">=medium") {
        width: 95%;
      }

      &.co-brand {
        display: none;
      }
    }
    .nav > li > a{
      padding: 20px 0px 25px 0px;
    }
  }
}
