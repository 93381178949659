.social{
  padding: 0px 15px;

  .heading{
    font-size: 24px;
    margin: 0 0 30px 0;
    text-align: center;

    .hastag{
      font-family: $font2-bold;
    }
  }

  .feed{
    padding: 0px;
  }

  .post{
    width: 100%;
    border: 1px solid $color5;
    margin-bottom: 15px;
    float: none;
    border-radius: 2px;

    .content{
      padding: 15px 20px;
      position: relative;
    }

    .name{
      font-family: $font2-bold;
      font-size: 14px;
      color: $color3;
      margin-bottom: 0px;
    }

    .short-name{
      font-size: 12px;
      color: $color5;
      margin-bottom: 10px;
    }

    .text{
      font-size: 12px;
      color: $color19
    }

    .image{
      height: 160px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .tag{
      height: 32px;
      width: 32px;
      position: absolute;
      top: -15px;
      right: 15px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .hashtag{
      font-family: $font1-bold;
    }

    &.no-image{
      .image{
        display: none;
      }
    }

    &.no-shortname{
      .name{
        margin-bottom: 10px;
      }
      .short-name{
        display: none;
      }
    }

    &.twitter{
      .tag{
        background-image: url("../images/social-tw.png");
      }
    }

    &.instagram{
      .tag{
        background-image: url("../images/social-ig.png");
      }
    }

    &.facebook{
      .tag{
        background-image: url("../images/social-fb.png");
      }
    }

    &:hover,
    &:focus,
    &:active{
      outline: none;
      text-decoration: none;
      .name{
        color: $color3;
      }
      .short-name{
        color: $color5;
      }
      .text{
        color: $color19
      }
    }
  }

  @include media(">=small") {
    padding: 0px;

    .heading{
      text-align: left;
    }

    .feed{
      padding: 0px 13px 0px 0px;
      &:first-child{
        padding-left: 0px;
      }
    }
  }
}