.agent-register {
  width: 100%;
  padding: 0 10px;

  &.register {
    margin: 60px auto;
  }

  &.profile {
    margin-top: 0;
  }

  .title {
    font-size: 24px;
    margin-bottom: 28px;
  }
  .instructions {
    color: $color-font-heading;
    margin-bottom: 18px;
    display: inline-block;
  }
  .form-item label {
    font-size: 12px;
  }
  .field-type-addressfield {
    .panel-heading {
      display: none;
    }
    .panel-body {
      padding: 0;
      overflow: visible;
    }
  }
  .password-confirm {
    margin-top: 0;
    color: $color-font-heading;
    font-family: $font1-semibold;
    font-size: 12px;
    padding-top: 26px;
  }
  div.form-item div.password-suggestions {
    border-color: $color-font-heading;
    color: $color-font-heading;
  }
  input[type="submit"] {
    width: 100%;
    text-transform: uppercase;
    margin: 0 auto;
  }
  .form-item-status,
  .form-item-roles,
  .help-block,
  .metatags-form,
  #edit-picture,
  #edit-timezone,
  #edit-ckeditor,
  #edit-overlay-control {
    display: none;
  }
  textarea {
    border-radius: 0;
    resize: none;
    max-height: 120px;
  }
  .password-strength {
    color: $color-font-heading;
    font-size: 12px;
    font-family: $font1-semibold;
    line-height: 35px;
  }
  fieldset {
    border: 0;
  }
  .panel {
    margin-bottom: 0px;
  }
  .field-name-field-notes {
    .grippie {
      display: none;
    }
  }

  @include media(">=small") {
    input[type="submit"] {
      width: 147px;
    }
  }

  @include media(">=medium") {
    width: 502px;
    padding: 0 16px;
    #edit-account {
      width: 147px;
      display: inline-block;
      margin-right: 10px;
    }
    #edit-field-agency-name,
    .form-item-field-address-und-0-thoroughfare  {
      width: 309px;
      display: inline-block;
    }
    .form-item-field-address-und-0-thoroughfare  {
      margin-right: 10px;
    }
    .form-item-field-address-und-0-premise {
      width: 147px;
      display: inline-block;
    }
    .form-item-field-address-und-0-postal-code,
    .form-item-field-address-und-0-locality,
    .form-item-mail,
    .password-field,
    .form-text.password-confirm {
      width: 228px;
      display: inline-block;
      margin-right: 10px;
    }
    .form-item-field-address-und-0-country,
    .form-item-field-address-und-0-administrative-area,
    .field-name-field-phone,
    .password-strength,
    .password-confirm {
      width: 228px;
      display: inline-block;
    }
    .form-item-field-address-und-0-postal-code,
    .form-item-field-address-und-0-locality {
      float: left;
    }
    .password-parent,
    .confirm-parent {
      width: 100%;
    }
    input[type="submit"] {
      margin: 0;
    }
  }
}
