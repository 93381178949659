#request-brochure-modal-wrapper,
#request-quote-modal-wrapper {
  text-align: center;
  .show-modal {
    margin: 0 0 30px 0;
  }
}

.request-brochure-modal,
.request-quote-modal {
   text-align: left;
  form {
    margin: 0 20px;
  }

  h2 {
    display: none;

    &.heading {
      font-size: 24px;
      text-align: center;
      margin-top: 10px;
      display: block;
    }
  }

  .heading {
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .heading,
  .note {
    text-align: center;
    padding: 0;
  }

  input[type="submit"].webform-submit {
    width: 100%;
    background-color: $color1;
  }
}

@include media(">=small") {
  #request-brochure-modal-wrapper {
    text-align: left;

    .show-modal{
      margin: 0 0 30px 15px;
    }
  }

  .request-brochure-modal {
    .heading,
    .note {
      padding: 0 50px;
    }
  }
}
