.tour-details-pricing{
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $color6;

  .prices{
    @include custom-container();
  }

  .table-header{
    margin: 0;
    font-size: 16px;
    font-family: $font1-bold;
  }

  .footnote{
    margin: 0;
  }

  .light-text{
    font-family: $font1-semibold;
    color: $color5;
  }

  .plane-trip{
    position: relative;
    padding-left: 20px;
    margin: 10px 0px 0px 0px;
    .icon{
      left: 0;
      top: 0;
      color: $color1;
      position: absolute;
    }
  }

  .price-section{
    margin-bottom: 25px;
    &:nth-child(3){
      margin-bottom: 0px;
    }
    .table{
      margin: 25px 0px;
    }
  }

  // CUSTOM LAYOUT TO SHOW SINGLE ROWS WITHOUT COLUMNS INSTEAD OF A TABLE
  .mobile-section{
    margin-bottom: 15px;
    .table-header{
      font-family: $font1-bold;
      color: $color3;
      padding: 12px;
      border-bottom: 1px solid $color11;
      background-color: $color7;
    }
    .plane-trip{
      background-color: $color7;
      margin-top: 0px;
      padding: 0px 12px 12px 20px;
      border-bottom: 1px solid $color11;
    }
    .icon{
      left: 3px;
    }
    .no-border{
      border: none;
    }
    .block-section{
      background-color: $color7;
      &.divided{
        margin-top: 15px;
      }
      &:nth-child(2){
        margin-top: 0px;
      }
    }
    .block-row{
      padding: 12px 0px;
      margin: 0px 12px;
      border-bottom: 1px solid $color11;
      &:last-child{
        border: none;
      }
    }
    .identifier{
      color: $color16;
      font-family: $font1-semibold;
      float: left;
    }
    .value{
      font-family: $font1;
      float: right;
    }
    .footnote{
      margin-top: 15px;
    }
  }

  .table{
    width: 100%;
    thead{
      border: none;
    }
    tbody{
      border: none;
    }
    tr{
      border: 1px solid $color11;

      th, td{
        border: 1px solid $color11;
        width: 25%;
        text-align: center;
        padding: 20px 10px;
      }
    }
    &.no-header{
      thead{
        tr{
          border: none;
        }
        th{
          padding: 0;
        }
      }
    }
  }

  @include media(">=small") {
    background-color: $color7;
  }
}
