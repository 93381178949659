// Panels Bootstrap Layout Builder Adaptations.
.row.panels-bootstrap-row-two_columns-main {
  padding: 30px 0;
  @include media(">=small"){
    padding: 60px 0;
  }
}

.node-panel, #panels-edit-display-form {
  .panels-bootstrap-two_columns{
    .container, .row, .container-fluid {
      overflow: visible;
    }
  }
  .panels-bootstrap-two_columns.container-fluid{
    overflow: visible;
  }
}
// Helper clases that can be used on panels.
.bg-reverse {
  background-color: $color-body-bg-reverse;
}

// Block messages on panels.
.pane-content {
  .alert {
    margin: 10px;
  }
  .reset-margin-top {
    margin-top: 0px;
  }
}

// Removing overflow hidden from article
.overflowed_content{
  overflow: visible;
}
