#faqs .panels-bootstrap-row-two_columns-main {
  background: $color10;
  padding: 30px 0px;
  @include media(">=small") {
    background:$color7;
    padding: 60px 0px;
  }
}
.wp_faqs{
  h2{
    font-family: $font2;
    font-size: 22px;
    text-align: center;
    margin: 0;
    @include media(">=small") {
      font-size: 29px;
      text-align: left;
    }
  }
  .question_content{
    border-bottom: 1px solid $color10;
    background-color: $color7;
    padding: 10px;
    margin: 20px 0;
    @include media(">=small") {
      margin: 0;
      padding: 25px 0;
    }
    &:last-of-type{
      border: none;
    }
  }
  .question{
    color: $color3;
    font-family: $font1-semibold;
    position: relative;
    margin-bottom: 0;
    padding-right: 34px;
    img{
      position: absolute;
      right: 0;
      top: 27%;
      width: 15px;
      @include media(">=small") {
        display: none;
      }
    }
  }
  .answer{
    display: none;
    @include media(">=small") {
      display: block;

    }
  }

}
.active{
  >.rotate{
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

@include media(">=small") {
  .wp_faqs{
    .question{
      margin-bottom: 15px;
    }
  }
}
