.wp-search {
  .menu-options {
    position: relative;
  }

  .tabs-mobile {
    padding: 50px 0 40px 0;
    position: relative;

    .tab-selector {
      position: relative;
      top: 0;
    }
  }
}

.search-tabs {
  .menu-options {
    // Styles to copy the nav-tabs from Bootstrap
    .tab-option {
      float: left;
      margin-bottom: -1px;

      a:focus {
        background-color: transparent;
      }
    }

    .tab-option.active > a {
      cursor: default;
    }
  }
}
