.message_404{
  width: 90%;
  height: 222px;
  color: $color3;
  background-color: $color7;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @include media(">=small") {
    width: 472px;
  }
  h1{
    font-size: 65px;
    font-family: $font2;
    margin-top: 24px;
  }
  p{
    font-size: 18px;
    font-family: $font1;
    margin-bottom: 20px;
  }
  a{
    width: 176px;
  }
}
.hero_404_margin{
  margin-bottom: 50px;
}
