#getting_started .panels-bootstrap-row-two_columns-main {
  background: $color10;
  padding: 30px 0px;
  @include media(">=small") {
    background:$color7;
    padding: 60px 0px;
  }
}
.wp_getting_started {
  h2{
    text-align: center;
    margin: 0 0 25px 0;
    @include media(">=small") {
      text-align: left;
    }
  }
  .box{
    float: none;
    overflow: auto;
    border-bottom: 1px solid $color18 ;
    background:$color7;
    text-align: center;
    padding: 29px 20px 18px 20px;
    margin-bottom: 20px;
    font-size: 15px;
    @include media(">=small") {
      padding: 28px 0;
    }
    &:last-of-type{
      border-bottom: none;
    }
    img{
      margin-bottom: 25px;
      @include media(">=small") {
        margin: 0;
        float: left;
      }
    }
    .info_content{
      text-align: left;
      @include media(">=small") {
        float: left;
        width: 80%;
        padding-left: 30px;
      }
      .title{
        font-family: $font1-bold;
        color: $color3;
        margin-bottom: 22px;
      }
      .btn{
        margin-top: 20px;
        color: $color7;
        background-color: $color1;
        width: 100%;
        @include media(">=small") {
          width: 180px;
          margin-top: 28px;
        }
      }
    }
  }
}
