// Colors Palette (I use Atom"s pigments package to see the color inline);
$color1: #c59b4f;
$color2: #dd5800;
$color3: #573f28;
$color4: #343434;
$color5: #bbbbbb;
$color6: #f3f3f3;
$color7: #ffffff;
$color8: #933d0b;
$color9: #f7b700;
$color10: #eeebe9;
$color11: #d2d2d2;
$color12: #2d2014;
$color13: #ff0000;
$color14: #ffc20f;
$color15: #624f36;
$color16: #9A8C7E;
$color17: #796553;
$color18: #eeebe9;
$color19: #000000;
$color20: #434343;
$color21: #CCC5BE;
$color22: #d06200;
$color23: #eeeeee;
$color24: #C80006;
$color25: #f5f5f5;
$color26: #D6BA8D;

// Color Vars.
$color-header-fg: $color7;
$color-navbar-fg: rgba($color3, 0.9);
$color-navbar-bg: $color7;
$color-footer-bg: $color12;
$color-footer-fg: rgba($color7, 0.8);;
$color-body-bg: $color7;
$color-body-bg-reverse: $color6;
$color-font-heading: $color3;
$color-font-body: $color4;
$color-font-link: $color1;
$color-font-link-hover: $color3;
$color-header-border: rgba($color8, 0.1);
$color-button1-bg: $color2;
$color-button2-bg: $color1;
$color-map-fg: rgba($color1, 0.3); // All regions
$color-map-stroke: $color7;
$color-map-region: $color1; // The enabled regions.
$color-map-hover: $color8; // When hovering an enabled region.
$color-form-text: $color21;
$color-error-form: $color22;
$color-form-validation: $color24;

// Z-Indexes.
$index1: 20;
$index2: 40;
$index3: 50;
$index4: 60;
$index5: 70;
$index6: 80;

// Fonts
$font1: "source_sans_proregular", sans-serif;
$font1-bold: "source_sans_probold", sans-serif;
$font1-semibold: "source_sans_prosemibold", sans-serif;
$font1-italic: "source_sans_proitalic", sans-serif;
$font1-light: "source_sans_prolight", sans-serif;
$font1-lightit: "source_sans_lightit", sans-serif;

$font2: "loraregular",  sans-serif;
$font2-bold: "lorabold",  sans-serif;
$font2-italic: "loraitalic",  sans-serif;

// General Settings
$button-font-size: 12px;
$gutter-with-sm: floor($grid-gutter-width / 2);

// Nodes Related
$card-width: 470px;
$card-height: 310px;
$card-title-size: 18px;
$card-copy-size: 14px;
$card-icon-height: 22px;
$card-icon-width: 25px;
$arrow-back-content: "\2190";
$see-more-btn-width: 176px;
$expert-width: 230px;

// Screen Size Breakpoints (From Boostrap)
$breakpoints: (small: $screen-sm-min, medium: $screen-md-min, large: $screen-lg-min, customTablet: 1024px, extraSmall: 400px);
