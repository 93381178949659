// Use this classes along with the Bootstrap btn class.
.btn {
  font-size: $button-font-size;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
  font-family: $font1-bold;
  box-sizing: border-box;
  &.button-1{ // Primary button.
    background-color: $color2;
    color: $color7;

    &.button-1:hover,
    &.button-1:active {
      background-color: $color3
  	}
  }

  &.button-2 { // Secondary Button.
    border-color: $color1;
    background-color: $color1;
    color: $color7;

    &:hover,
    &:active,
    &:focus {
      background-color: $color3;
    }
  }

  &.button-3 { // Tertiary Button.
    background-color: transparent;
    border: $color1 solid 2px;
    color: $color1;
    &:hover,
    &:active,
    &:focus {
      color: $color3;
      border-color: $color3;
    }
  }

  &.button-4,
  &.button-4:hover, &.button-4:active { // Quaternary Button.
    background-color: transparent;
    border: $color2 solid 2px;
    color: $color2;

    &:hover,
    &:active,
    &:focus {
      background-color: $color2;
      color: $color7;
    }
  }

  &.button-5 {
    background-color: transparent;
    border: 2px solid $color7;
    color: $color7;

    &:hover,
    &:active,
    &:focus {
      background-color: $color7;
      color: $color3;
    }
  }

  &.load-more {
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: $font1-bold;
    background-color: $color6;
    color: $color1;
    text-decoration: underline;
    padding: 0;
  }

  &.button-carret {
    $fgColor: rgba($color3, 0.6);
    $carretSize: 4px;
    background-color: $color7;
    border: 1px solid rgba($color3, 0.3);
    color: $fgColor;
    text-align: left;
    width: 180px;
    position: relative;
    font-family: $font1-light;
    font-size: 14px;
    text-transform: capitalize;
    height: 50px;
    @include media(">=small") {
      height: 40px;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 24px;
      right: 12px;
      border-left: $carretSize solid transparent;
      border-right: $carretSize solid transparent;
      border-top: $carretSize solid $fgColor;
      @include media(">=small") {
        top: 17px;
      }
    }
    &.open:after {
      transform: rotate(180deg);
    }
  }
}
