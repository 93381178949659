.wp_supportmaterials {
  h2 {
    font-family: $font2;
    font-size: 22px;
    text-align: center;
    margin: 0 0 25px 0;
    @include media(">=small") {
      font-size: 29px;
      text-align: left;
    }
  }
}
