.brand {
  color: $color20;
  padding-bottom: $grid-gutter-width;
  position: relative;
  text-align: center;

  .logo-container {
    border-bottom: 1px solid $color18;
    padding: $grid-gutter-width $gutter-with-sm;
  }

  .btn {
    bottom: $grid-gutter-width;
    left: $gutter-with-sm;
    position: absolute;
    width: calc(100% - #{$grid-gutter-width});
    white-space: normal;
  }

  .copy {
    padding: $grid-gutter-width $gutter-with-sm;
  }

  .description {
    margin-bottom: $grid-gutter-width;
    text-align: left;
  }

  p {
    margin-bottom: 0;
  }
}
