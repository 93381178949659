/* Shared styles by accommodation and journal */
.accommodation,
.journal {
  $image-height: 344px;
  $margin: 20px;

  .copy {
    padding: $grid-gutter-width $gutter-with-sm;
  }

  .image {
    padding: 0;

    figure {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: $image-height;
    }
  }

  .info {
    color: $color3;
    margin-bottom: $margin;
  }

  .title {
    margin-top: 0;
    margin-bottom: $margin;
  }
}

.accommodation {
  .rating {
    margin-bottom: 20px;
  }

  .reviews {
    font-size: 12px;
  }

  &.card {
    .info.more {
      max-height: 100px;
    }
  }
}
