.wp_hero_carousel{
  .wp-hero{
   // z-index:100 !important; // "!important" in order to replace inline styles added by the Slick library
  }

  .slick-initialized .slick-slide{
    display: table;
  }
  .slick-slider .slick-track, .slick-slider .slick-list{
    transform: inherit;
    -webkit-transform: inherit;
  }
  .slick-list{
    overflow: visible;
  }
  .slick-slider{
    margin-bottom: 0;
    //display: table;
    .slick-dots{
      bottom: 8px;
      z-index: 101;
      li{
        width: 26px;
      }
      li button:before{
        font-size: 34px;
        color: #fff;
        opacity: 0.5
      }
      li.slick-active button:before{
        opacity: 1;
      }
    }
  }
  .left_dots{
    .slick-dots{
      top: 0;
      bottom: 0;
      left: 10px;
      height: 80px;
      margin: auto;
      width: 20px;
      li{
        display: block;
      }

    }
  }
  .right_dots{
    .slick-dots{
      top: 0;
      bottom: 0;
      right: 10px;
      right: 22px\0;
      height: 80px;
      margin: auto;
      width: 20px;
      ul{

      }
      li{
        display: block;
      }

    }
  }

}