.block-social-feed {
  .title {
    font-family: $font2;
    margin: 0;
    margin-bottom: 42px;
    strong {
      font-family: $font2-bold;
    }
  }
  .referal {
    display: none;
  }
}
