.apply-now-modal {
  h2 > a {
    display: none;
  }
  
  .heading {
    font-family: $font2;
    font-size: 24px;
    margin-bottom: $grid-gutter-width;
    text-align: center;
  }
}
