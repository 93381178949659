.journal {
  $image-height: 344px;
  $margin: 20px;

  .image {
    padding: 0;

    figure {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: $image-height;
    }
  }

  .info.more {
    max-height: 150px;
  }

  .published-by {
    font-size: 12px;
    margin-bottom: $margin;
  }
}

.journal.view_entry,
.journal.full {
  $image-height: 230px;
  $info-height: 40px;

  .copy {
    padding-left: 0;
    padding-right: 0;
  }

  .image {
    figure {
      min-height: $image-height;
    }
  }

  .info {
    color: $color20;
    margin-bottom: 10px;
  }

  .info.more {
    max-height: $info-height;
  }

  .published-by {
    margin-bottom: $grid-gutter-width;
  }

  .published-by {
    color: $color20;
    font-size: 14px;
  }

  .title {
    color: $color20;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.journal.view_entry,
.journal.full,
.journal.large {
  .see-more {
    width: auto;
    padding: 0;
    border: none;
    color: $color1;
    background-color: transparent;
    border-bottom: 1px solid $color1;
  }
}

.journal.large {
  .info {
    width: 100%;
  }

  .see-more {
    margin-bottom: 20px;
  }
}

.journal.full{
  .info.more{
    max-height: none;
  }
}

@include media(">=small") {
  .journal.view_entry,
  .journal.full{
    .info {
      margin-bottom: $grid-gutter-width;
    }
  }

  .journal.view_entry,
  .journal.full,
  .journal.large {
    $button-width: 190px;

    .see-more,
    .see-more:hover,
    .see-more:active {
      color: $color2;
      padding: 10px 12px;
      width: $button-width;
      border: $color2 solid 2px;

      &:hover,
      &:active,
      &:focus {
        background-color: $color2;
        color: $color7;
      }
    }
  }
}
