.wp_destination{
  text-align: center;
  background-color: $color6;
  overflow: visible;
  .container{
    @include media(">=small") {
      padding: 0;
    }
  }

  h1{
    font-family: $font2;
    font-size: 29px;
    margin: 60px 0 20px 0;
    @include media("<small") {
      font-size: 22px
    }
  }
  .text{
    font-family: $font1;
    font-size: 18px;
    margin: 0 auto 60px auto;
    width: 80%;
    @include media("<small") {
      font-size: 15px;
      width: 100%;
      text-align: left;
      margin:0 auto 30px auto;
    }
  }
  .countries{
    float: left;
    background-color: $color7;
    text-align: left;
    color: $color3;
    margin-top:18px;
    border-radius: 4px;

    @include media("<small") {
      float: none;
      width: 100%;
    }
    span{
      font-family: $font2-bold;
      padding:19px 0 19px 12px;
      display: block;
      border-bottom: 1px solid $color18;
      font-size: 18px;
      @include media("<small") {
        padding:10px 0 10px 20px;
        border-bottom: none;
      }
    }
    ul{
      font-family: $font1;
      font-size: 14.2px;
      list-style-type: none;
      text-align: left;
      padding-left: 12px;
      padding-top: 19px;
      @include media("<small") {
        padding-top: 0px;
        padding-left: 29px;
      }
      li{
        cursor: pointer;
        padding:0 0 19px 0;
        opacity: 0.6;
        color: $color3;
        @include media("<small") {
          padding:10px 0 10px 0;
          border-top: 1px solid $color18;
        }
        &:hover{
          text-decoration: underline;
        }
      }
      .selected{
        font-weight: bold;
      }
    }
  }
  .country_info_container{
    float: left;
    position:relative;
    margin-top: 18px;
    @include media("<small") {
      float: none;
      width: 100%;
      margin-bottom:18px;
    }
    >img{
      z-index: 2;
      position: absolute;
      left: 301px;
      top: 9px;
      &:hover{
        cursor: pointer;
      }
      @include media("<small") {
        display: none;
      }
    }
  }
  .country_info{
    position: absolute;
    top:0;
    left: 30px;
    width: 293px;
    background-color: $color7;
    text-align: left;
    overflow: auto;
    z-index: 1;
    border-radius: 4px;
    @include media("<small") {
      position: static;
      width: 100%;
    }
    img{
      max-height: 210px;
      width: 100%;
    }
    >div{
      width: 90%;
      margin: auto;
    }
    h2{
      font-family: $font2-bold;
      font-size: 18px;
      margin: 30px 0;
    }
    p{
      font-family: $font1;
      font-size: 14.2px;
    }
    a{
      background-color: $color1;
      font-family: $font1-bold;
      color: $color7;
      text-align: center;
      padding: 10px 0px;
      width: 100%;
      display: block;
      margin: 30px 0 35px 0;
      cursor: pointer;
      &:hover{
        text-decoration: none;
        background-color: $color3;
      }
    }

  }
  .map_container{
    float: left;
    padding: 0;

    @include media("<small") {
      display: none;
    }
    .block-ati-map{
      background-color: $color6;
      padding: 0px 0 60px 0;

      .vmap{
        max-width: 814px;
        .vmap-inner{
          max-width: 814px;
        }
      }
    }
  }
}
