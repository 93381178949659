.ati-form {
  $column-width: 50%;

  overflow: visible;

  .form-group{
    font-size: 17px;
    margin-bottom: 20px;
  }

  .form-required {
    color: $color-font-heading;
  }

  .input-label, label{
    font-family: $font1-semibold;
    color: $color-font-heading;
    padding: 0px;
    margin: 0px;
    font-size: 17px;
  }

  .inline-item {
    width: $column-width;
    display: inline-block;
    float: left;

    &.inline-item-first {
      width: calc(#{$column-width} - 15px);
      margin-right: 15px;
    }

    @include media("<=extraSmall"){
      width: 100%;
      &.inline-item-first{
        width: 100%;
      }
    }
  }

  .form-item{
    margin-bottom: 18px;
  }

  .reset-margin{
    margin-bottom: 0px;
  }

  .one-column-item {
    width: $column-width;
  }

  .hidden-label label {
    display: none;
  }

  .description {
    font-size: 12px;
    margin: $gutter-with-sm 0 0 0;
    opacity: 0.5;
  }

  .webform-component--logo-description{
    padding-top: 110px;
    @include media(">=small") {
      padding-top: 0;
    }
  }
  .webform-component--time{
    margin-bottom: 0;
  }

  input[type="text"],
  input[type="email"],
  [type="password"] {
    border: none;
    border-radius: 0px;
    padding: 5px 0px 5px 0px;
    height: 35px;
    color: $color-font-heading;
    font-family: $font1-light;
    border-bottom: 1px solid $color-form-text;
    font-size: 17px;
    background: transparent;
    &.error{
      border: none;
      border-bottom: 1px solid $color-error-form;
    }
  }

  input[type="submit"].webform-submit{
      width: 150px;
      border: 1px solid transparent;

      &.block{
        width: 100%;
      }

      @include media("<small") {
        width: 100%;
      }
  }

  input[type="radio"].form-radio{
    position:relative;
    margin-right: 10px;
    margin-left: -23px;
    margin-top: 4px;
  }
  .radio-group{
    .input-label,label{
      margin-bottom: 10px;
    }

    .inline-radio{
      display: inline-block;
      &:first-child{
        margin-right: 30px;
      }
    }

    input[type="radio"]{
      display: none;
    }

    .custom-radio,.form-radio{
      padding: 2px;
      height: 16px;
      width: 16px;
      margin: 0px;
      border-radius: 50%;
      border: 1px solid $color-font-heading;
      background-color: $color7;
      background-clip: content-box;
      display: inline-block;
      vertical-align: top;
      margin-top: 3px;
    }

    .radio-label{
      padding: 0px 0px 0px 10px;
      margin: 0px;
      color: $color-font-heading;
      font-family: $font1-light;
    }

    input[type="radio"]:checked + .custom-radio{
      background-color: $color-font-heading;
    }
  }

  .checkbox{
    margin-top: 0px;

    input[type="checkbox"]{
      display: none;
    }

    .custom-checkbox{
      padding: 3px;
      height: 20px;
      width: 20px;
      border: 1px solid $color-font-heading;
      background-color: $color7;
      background-clip: content-box;
      display: inline-block;
      vertical-align: top;
    }

    .checkbox-label{
      padding: 0px 0px 0px 10px;
      color: $color-font-heading;
      font-family: $font1-light;
      margin: 0px;
    }

    input[type="checkbox"]:checked + .custom-checkbox{
      background-color: $color-font-heading;
    }
  }

  textarea{
    display: block;
    width: 100%;
    height: 160px;
    resize: none;
    padding: 5px;
    margin-top: 15px;
    color: $color-font-heading;
    border: 1px solid $color-form-text;

    &:focus,
    &:active{
      outline: none;
    }
  }

  .bootstrap-select{
    display: block;

    /* Overrride of bootstrap-select default styles */
    >select{
      left: 0;
      opacity: 1 !important;
      width: 100% !important;
    }

    &.open .dropdown-toggle{
      border: none;
    }

    .dropdown-toggle{
      border: none;
      height: 35px;
      padding-left: 0px;
      padding-right: 0px;
      border-bottom: 1px solid $color-form-text;
      > span.filter-option{
        color: $color-font-heading;
      }
    }

    .dropdown-menu .inner{
      max-height: 150px !important;
      > li > a{
        color: $color-font-heading;
      }
    }

    .caret{
      border-width: 6px;
    }
  }

  /**
   *  Validator styles
   */
  .parsley-errors-list {
    margin-bottom: 0px;
    padding: 0;
    color: $color2;
    font-size: 12px;
    // The float prevents the form from growing. Make sure there is enough space.
    float: left;
    li {
      list-style: none;
    }
  }
  input.parsley-error {
    border-bottom-color: $color2;
  }

  @include media(">=small") {
    .form-group{
      font-size: 14px;
      margin-bottom: 25px;
    }

    .input-label,
    label {
      font-size: 14px;
    }

    .inline-group{
      display:flex;
      justify-content: space-between;

      .form-group{
        width: 48%;
      }
    }

    input[type="text"]{
      font-size: 14px;
    }

    .checkbox {
      .checkbox-label {
        padding: 2px 0px 0px 10px;
      }
    }

    .radio-group {
      .custom-radio {
        margin-top: 0px;
      }
    }
  }

  .form-clearfix {
    overflow: hidden;
    width: 100%;
  }
}
