.co-brand-partnership {
  .heading {
    font-family: $font1;
    font-size: 24px;
    margin: 0 0 $grid-gutter-width 0;
  }

  .wp-static-content {
    margin-bottom: $grid-gutter-width;

    p {
      margin: 0;
    }
  }

  @include media(">=medium") {
    .co-brand-form {
      form {
        width: 85%;
      }
    }
  }

  @include media(">=large") {
    .co-brand-form {
      form {
        width: 70%;
      }
    }
  }
}
