#user-pass-reset {
  margin: 120px auto;
  width: 270px;
  p {
    font-size: 12px;
    color: $color-font-heading;
    font-family: $font1-semibold;
  }
  @include media(">=small") {
    width: 502px;
  }
}
