.toolbar-sort {
  margin-bottom: 30px;
  .label {
    color: $color-font-heading;
    font-size: 12px;
    font-family: $font1-semibold;
    padding: 0;
    margin-bottom: 6px;
    display: block;
    text-align: left;
  }
  .toolbar-buttons {
    letter-spacing: 16px;
  }
  button {
    letter-spacing: normal;
  }
}
