

.material_content{
  border: 1px solid $color10;
  padding: 15px 15px 12px 15px;
  margin: 10px 0;
  width: 100%;
  @include media(">=small") {
    width: 85%;
  }
}
.title{
  color: $color3;
  font-family: $font1-semibold;
  position: relative;
  margin: 0;
  img{
    position: absolute;
    right: 0;
    top: 27%;
    width: 15px;
  }
}
.text{
  display: none;
  margin-top: 15px;
  p{
    margin-bottom: 3px;
  }
  a{
    text-decoration: underline;
  }
}

.active{
  >.rotate{
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}
