.partner-content{
	background-color: $color7;
	text-align: center;
  padding-top: 15px;
  width: 80%;
  margin: auto;
    @media screen and (max-width: 1023px) {
    	width: 90%;
  	}
  	&>div{
		width: 100%;
	}
	.partner{
		text-align: center;
		margin-bottom:60px;
		width: 16%;
		display: inline-block;
    @include media("<=small") {
      margin-bottom: 6px;
    }
    img{
      margin:auto;
      max-height: 73px;

    }
		@media screen and (max-width: 1023px) {
    	width: 32%;
  	}
  	@media screen and (max-width: 767px) {
    	width: 49%;
  	}
	}

	a {
		color: $color3;
		text-decoration: underline;
	}

	p{
		font-family: $font1;
		color: $color3;
		margin-bottom:60px;
		font-size: 11px;
	}
}
