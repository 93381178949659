.wp-tourdetails {
  position: relative;

  hr {
    margin: 0;
  }

  /*****
   ***** HEADER SECTION
   *****/

  .details-header {
    @include custom-container();
    position: relative;
  }

  .recap {
    padding: 20px 0px;
    background-color: $color7;

    .title {
      color: $color15;
      font-size: 22px;
      font-family: $font2;
      margin-bottom: 10px;
    }

    .info {
      margin-bottom: 12px;

      .copy {
        margin: 0;
        font-size: 16px;
      }
    }

    .country::before,
    .night::before {
      content: '|';
      padding-right: 3px;
    }

    .country:first-child::before,
    .night:first-child::before {
      content: '';
      padding: 0;
    }
  }

  .selectpicker {
    display: none;
  }

  .airfare-copy {
    margin: 0px;
    font-size: 12px;
  }

  .tier {
    width: 100%;
    .bootstrap-select {
      width: 100%;
    }
    >span {
      display: block;
      font-family: $font1-bold;
      color: $color3;
      margin-bottom: 5px;
    }
  }

  .quote,
  .book {
    width: 100%;
    height: 50px;
    margin: 10px 0px 0px 0px;
  }

  .book.no-book {
    display: none;
  }

  /*****
   ***** CONTENT SECTION. To check the style for each tab content, please check the partial scss folder
   *****/

  .tab-selector {
    @include custom-container();
    width: 100%;
    top: -65px;
    position: absolute;
  }

  .menu-options {
    @include custom-container();
  }

  .heading {
    margin: 0px;
    color: $color3;
    font-family: $font2;
    font-size: 20px;
    padding-bottom: 22px;
    text-align: center;
  }

  .description {
    margin: 0px;
  }

  @include media(">=small") {
    .heading {
      text-align: left;
    }

    .header-section {
      width: 50%;
      min-height: 80px;
      margin-bottom: 60px;
      position: relative;
    }

    .quote,
    .book {
      height: 39px;
    }

    .recap {
      left: 0;
      top: -165px;
      width: 100%;
      margin: 30px 0px;
      position: absolute;
      padding: 18px 30px;
      background-color: rgba(255, 255, 255, 0.85);
      min-height: 150px;
    }

    .social-icons-and-request {
      top: 10%;
      right: calc(-100%);
      position: absolute;
    }

    .booking-options {
      display: inline-block;
      margin-top: 3px;
      &.no-tier {
        margin-top: 25px;
      }
    }

    .tier {
      width: 130px;
      &.no-book {
        display: inline-block;
      }
      //Override of Bootstrap Select library styles.
      .bootstrap-select {
        .dropdown-toggle {
          width: 130px;
        }
        .dropdown-menu {
          width: 130px;
          max-height: 75px !important;
        }
      }
    }

    .quote,
    .book {
      width: 130px;
      display: inline-block;
      margin: 0px;
    }
  }

  @include media(">=medium") {
    .tier {
      display: inline-block;
    }
  }
}
