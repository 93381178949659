.tour-details-addons{
  background-color: $color6;

  .cards{
    @include custom-container();
    padding-top: 60px;
    padding-bottom: 60px;

    .tour{
      margin-bottom: 15px;
    }
  }

  .cards-container{
    text-align: center;
  }

  @include media(">=medium") {
    .cards-container{
      .tour:nth-child(odd){
        margin-right: 15px;
      }
    }
  }

  @include media(">=small") {
    .cards-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}