/* Styles for the 'teaser' view_mode */
.expert {
  background-color: $color7;
  overflow: hidden;
  border-radius: .2em;
  padding: 0;
  margin: 0 auto;

  .information {
    width: 100%;
    padding: 15px;

    .name {
      margin-bottom: 0;
    }
  }

  .card {
    em {
      font-family: $font1-semibold;
      font-style: normal;
    }
  }

  .btn {
    width: 100%;
  }
}

.expert--photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $expert-width;
  width: 100%;
}

/* Styles for the 'full' view_mode */
.expert-info {
  $img-width: 150px;

  border-bottom: 1px solid $color11;

  h1 {
    font-size: 24px;
    margin: 0 0 $grid-gutter-width 0;
    text-align: center;
  }

  u {
    cursor: pointer;
  }

  div {
    padding: 0 0 15px 0;
  }

  .photo {
    background-position: center;
    background-size: cover;
    border-radius: $img-width;
    height: $img-width;
    margin: auto;
    margin-top: 20px;
    width: $img-width;
  }
}

@include media(">=extraSmall") {
  .expert {
    max-width: $expert-width;

    .btn {
      width: 195px;
    }
  }
}

@include media(">=small") {
  .expert {
    margin-right: 3%;
  }

  .expert-info {
    padding-bottom: $grid-gutter-width;

    h1 {
      text-align: left;
    }

    .photo {
      margin: 0;
      margin-top: 20px;
    }
  }
}
