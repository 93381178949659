.testimonials {
  .author-archive {
    .author-name {
      color: $color20;
      display: inline-block;
      font-size: 16px;
    }

    .by-author {
      display: inline-block;
      font-family: $font1-light;
      color: $color20;
      margin-right: 3px;
    }

    .date {
      display: block;
    }
  }

  .help-block {
    display: none;
  }
}
