body {
  color: $color-font-body;
  font-family: $font1;
  font-size: 14px;
}

a {
  color: $color-font-link;

  &:active,
  &:focus,
  &:hover {
    color: $color-font-link-hover;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font2;
  color: $color-font-heading;
}

// Navbar dropdown fix.
.navbar, .navbar ul {
  overflow: visible;
}

// Panels admin.
#page-header {
  background-color: $color10;
  padding-left: 10px;
  border: 1px solid #d2d2d2;
  border-width: 1px 0 1px 0;
  .tabs, .nav-tabs {
    margin: 0;
    a {
      color: $color-font-heading;
    }
  }
}

#panels-edit-display-form, #panels-choose-layout {
  padding: 20px;
}

.icon-star{
  &.filled{
    color: rgba(255, 194, 15, 1);
  }
  &.empty{
    color: rgba(211, 210, 210, 1);
  }
}

.modal-backdrop{
  opacity:0.8 !important;
}

.single-blog-entry{
  padding: 30px 15px;
  @include media(">=small") {
    padding: 60px 0px;
  }
}

/* Hiding GA iframe generated by the Google Analytics Module */
iframe[name='google_conversion_frame']{
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  margin-top: -13px;
  float: left;
}