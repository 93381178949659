.menu-options{
  margin: 0;
  width: 100%;
  display: flex;
  background: $color7;
  margin: 0;
  border: none;

  .tab-option{
    flex: 1;

    .tab-control{
      margin: 0;
      padding: 0;
      border: none;
      color: $color3;
      padding: 25px 0px;
      text-align: center;

      &:hover{
        background: none;
      }
    }

    &.active{
      border-bottom: 4px solid $color14;

      .tab-control{
        color: $color3;
        font-family: $font1-bold;
        background: none;
        border: none;
      }
    }
  }
}

.tab-pane.fade{
  opacity: 0;
  transition: opacity .15s linear;

  &.in{
    opacity: 1;
  }
}