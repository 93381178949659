// Page Layout
// -----------------------------------------------------------------------------

#main{
  position: relative;

  #messages{
    top: 70px;
    width: 100%;
    opacity: 0.9;
    z-index: $index5;
    padding: 0px 35px;
    position: absolute;
  }

  .misc-title{
    text-align: center;
  }

  @include media(">=small") {
    .misc-title{
      text-align: left;
    }

    #messages{
      position: static;
      padding: 0;
    }
  }
}