.foundation {
  width: 100%;

  $logo-container-size: 90px;
  $logo-container-radius: 5px;
  $link-margin: $gutter-with-sm;

  a {
    text-decoration: underline;
  }

  p {
    margin: 0;
  }

  .copy {
    padding: 20px 35px 20px 20px;
    width: 100%;
  }

  .image {
    background-repeat: no-repeat;
    max-width: 309px;
    min-height: 344px;
    position: relative;
    width: 100%;
  }

  .links {
    margin-top: 25px;

    .link:not(:last-child) {
      &:after {
        content: "|";
        display: inline-block;
        margin: 0 $link-margin;
      }
    }
  }

  .logo-container {
    background-color: $color7;
    border-bottom-left-radius: $logo-container-radius;
    border-top-left-radius: $logo-container-radius;
    height: $logo-container-size;
    line-height: $logo-container-size;
    position: absolute;
    text-align: center;
    top: 30px;
    right: 0;
    width: $logo-container-size;
  }

  .title {
    margin: 0 0 15px 0;
  }

  @include media(">=small") {
    display: inline-flex;

    .copy,
    .image {
      float: left;
    }
  }
}
