.basic-page {
  padding: $grid-gutter-width;
  
  .list-subtitle {
    font-family: $font1-semibold;
  }

  .list-title {
    color: $color3;
    font-family: $font1-bold;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
    margin: 0;
  }
}
