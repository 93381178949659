.block-agent-password {
  width: 270px;
  margin: 60px auto;
  .title {
    margin: 0;
    margin-bottom: 36px;
  }
  @include media (">=small") {
    width: 320px;
  }
}
