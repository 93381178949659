.modal{

  .close{
    top: 15px;
    right: 25px;
    font-size: 40px;
    position: absolute;
  }

  //form styles
  .ati-form{

    input[type="submit"].webform-submit{
      width: 100%;
    }
  }

}