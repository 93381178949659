.wp-request-quote{
  padding: 0px 15px;

  .heading{
    margin: 0px 0px 10px 0px;
    color: $color3;
    font-family: $font2;
    font-size: 24px;
    line-height: 28px;
  }

  button[type="submit"]{
    width: 150px;
  }

  @include media(">=small") {
    padding: 0px;
    .note{
      font-size: 14px;
    }
  }
}
