
.program_content{
  border-bottom: 1px solid $color10;
  padding: 25px 0px;
  .title{
    color: $color3;
    font-family: $font2-bold;
    font-size: 16px;
    margin-bottom: 15px;
    @include media(">=small"){
      font-size: 18px;
    }
  }
  >div{
    ul{
      padding-left: 17px;
    }
    a{
      text-decoration: underline;
    }
  }
}
