.tour {
  position: relative;
  display: inline-block;
  width: 100%;
	height: auto;
	border-radius: 4px;
	color: $color4;

	.section{
		min-height: 260px;
		overflow: hidden;
    height: auto;
    float: left;
	}

	.image{
		padding: 0;
		width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
	}

  .tag-label{
    bottom: 0px;
    left: 0;
    min-width: 70px;
    display: inline-block;
    position: absolute;
    background-color: $color4;
    font-family: $font1-semibold;
    color: $color14;
    padding: 5px 0px;
    display: none;
    text-align: center;
  }
  .description{
    display: none;
  }
  &.special_card{
    .tag-label.special{
      display: block;

    }
  }

  &.add_on_card{
    .tag-label.add-on{
      display: block;
    }

    .content {
      padding: 30px 25px;
      .req-quote, .book-now{
        display: none;
      }
    }
  }
  &.large_card{
    .description{
      display: block;
      margin-bottom: 28px;
    }
    .content .rating .rate{
      margin-bottom: 10px;
    }
  }

  .airfare{
    color: $color2;
    font-size: 15px;
    font-family: $font1-bold;
  }

	.content{
		padding: 15px 25px 55px 25px;
		width: 100%;
		position: relative;
    text-align: left;
  	.title{
      margin-bottom: 10px;
      padding-right: 65px;
      @include media(">=small") {
        padding-right: 54px;
      }
      >a{
        color: $color3;
      }
  	}

  	.info {
  		margin-bottom: 25px;

      .copy{
        margin: 0;
      }

      .country::before{
        content: '|';
        padding-right: 3px;
      }

      .country:first-child::before{
        content: '';
        padding: 0;
      }
  	}

  	.rating{
      margin: 0;

      .rate{
        font-size: 12px;
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 25px;
      }
      .quantity{
        font-family: $font1-semibold;
        font-size: 12px;
      }
  	}

  	.req-quote, .book-now{
  		width: 45%;
  		position: absolute;
  		bottom: 15px;
  		left: 9px;
      padding: 10px 0;
  	}
    .book-now{
      right: 9px;
      left: inherit;
    }
  }
  .special_icon{
    background-color: $color2;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0px;
    padding: 5px 10px;
    @include media(">=small") {
      right: -4px;
    }
    p{
      color: $color7;
      font-size: 13px;
      margin: 0;
      font-family: $font1-semibold;
    }
  }
	.details{
		margin-bottom: 25px;

		.detail{
			float: left;
			text-align: center;
      position: relative;
      margin-left: 15px;

      &:first-child{
        margin-left: 0px;
        .separator{
          display: none;
        }
      }
		}


		.detail-copy{
			margin-bottom: 0px;
      font-size: 10px;
		}

    .detail-image{
      display: inline-block;
      width: $card-icon-width;
      height: $card-icon-height;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

    }

    .separator{
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 30px;
      background-color: $color5;
    }

	}

  @include media(">=small") {
    display: inline-flex;

    .section{
      min-height: $card-height;
    }

    .tag-label{
      bottom: 20px;
    }

    &.teaser,
    &.special_card,
    &.add_on_card{
      min-width: 450px;
      /*max-width: 470px;*/

      .content{
        width: 60%;
        overflow: visible;
      }

      .image{
        width: 40%;
      }
    }

    &.large_card{
      .content{
        width: 60%;
        overflow: visible;
      }
      .image{
        width: 40%;
      }
    }
  }

  @include media(">=medium") {
    &.teaser,
    &.special_card,
    &.add_on_card{
      width: 49%;
    }
    &.large_card{
      .req-quote, .book-now {
        width: 26%;
        left: 15px;
      }
      .book-now {
        left: 32%;
      }
    }
    .content{
      padding: 15px 15px 55px 15px;
    }
  }

  @include media(">=large") {
    .content{
      padding: 15px 25px 55px 25px;
    }
  }
}
