/* General styles for the search (tours, accomodations and journals) */

.view-search-journals,
.view-search-tours,
.view-search-acommodations {
  .card {
    margin-bottom: $grid-gutter-width;
  }
}

.view-search-others {
  .views-field-title {
    a {
      color: $color3;
      font-family: $font2-bold;
      font-size: 18px;
    }
  }

  .views-row {
    margin-bottom: $gutter-with-sm;
  }
}
