.wp-cardsintabs {
  text-align: center;
  background-color: $color6;

  .container {
    overflow: visible;
    .cardsintabs-content{
      position: relative;
      width: 100%;
      height: auto;
      padding: 30px 0px 60px 0px;
    }
  }

  .tab-selector {
    width: 100%;
    top: -65px;
    position: absolute;
  }

  .menu-options {
    left: 0;
    top: -35px;
    position: absolute;

    .tab-control:hover {
      text-decoration: none;
    }
  }

  .copy-heading {
    margin: 0px;
    color: $color3;
    font-family: $font2;
    font-size: 24px;
    padding-bottom: 15px;
  }

  .copy-description {
    margin: 0px;
    font-size: 18px;
  }

  .safari-week {
    margin-top: 15px;
    padding-bottom: 25px;
    border-bottom: 2px solid $color18;

    .copy-heading {
      color: $color2;
      font-family: $font1-bold;
    }

    .tour {
      border: 2px solid $color2;
    }
  }

  .cards-container {
    margin-top: $grid-gutter-width;
    position: relative;
    .cards{
      margin-bottom: 60px;
      @include media(">=small") {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .tour {
      margin-top: $grid-gutter-width;
      &:first-child {
        margin-top: 0px;
      }
    }
  }

  .btn.pagination-end {
    display: none;
  }

  .loader {
    display: none;
  }

  .loading {
    .loader {
      left: 50%;
      bottom: 0px;
      display: block;
      position: absolute;
      transform: translateX(-50%);
    }
    .load-more {
      visibility: hidden;
    }
  }

  @include media(">=small") {
    .container{
      .cardsintabs-content {
        padding: 90px 0px 60px 0px;
      }
    }

    .copy-description {
      padding: 0px 70px;
    }

    .safari-week {
      padding: 0;
      border: none;
    }

    .cards-container {
      margin-top: 60px;
      &.inline {
        margin-top: $grid-gutter-width;
      }
    }
  }

  @include media(">=medium") {
    .cards-container {
      &.inline {
        .tour {
          margin: 0px;
          &:nth-child(n+3) {
            margin-top: 15px;
          }

          &:nth-child(odd) {
            margin-right: 1%;
          }
        }
      }
    }
  }
}
