
$headingPadding: 18px 0;

#page_contact{
  .hero-title{
    @include media("<small"){
      display: none;
    }
  }
}

.contact-info{

  @include media("<small") {

    .center-text{
      text-align: center;
    }
  }
  #panel-bootstrap-row-main{
    margin-top: 60px;
  }

  .ati-form {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .panel-col-last, .panel-col-first{
    margin-top: 50px;
  }

  .panel-col-first{
    margin-top: 40px;

  }

  .contact {
    padding-left: 0;
    padding-top: 30px;

    @include media("<small") {
      text-align: center;
    }
    a {
      color: $color3;
      display: inline-block;
      text-align: center;

       @include media("<small") {
        font-size: 17px;
      }
    }
    & > ul {
      padding: 0;
    }
    li {

      list-style: none;

      &.expanded{
        display: none;
      }

      &:last-of-type{

        a{
          color: $color1;
          text-decoration: underline;
          span{
            text-decoration: none;
          }
        }
        span{
          color: $color3;
        }
      }
    }
    ul li {
      position: relative;
      padding-left: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .icon:before {
      margin-right: 15px;
      font-size: 20px;
      text-decoration: none;
    }
  }

  .follow-info {
    margin-top: 20px;
    .btn {
      width: 160px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .follow {
      padding-bottom: 20px;

      .heading {
        text-transform: uppercase;
        font-family: $font1-bold;
        padding: $headingPadding;
        display: inline-block;
      }
      a {
        display: inline-block;
        letter-spacing: 15px;
        font-size: 22px;
      }
    }
  }

  @include media(">=medium") {
    .contact {
      ul li {
        padding-left: $grid-gutter-width;
      }
    }

    .follow-info {
      .follow {
        a {
          letter-spacing: 20px;
        }
      }
    }
  }
}