.tour-details-highlights{

  .main-highlight,
  .highlights{
    @include custom-container();
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .highlight{
    position: relative;
    padding-left: 50px;
    margin: 15px 0px 0px 0px;

    &:nth-child(2){
      margin-top: 0px;
    }
    .category{
      font-family: $font1-semibold;
    }
    .icon{
      position: absolute;
      font-size: 18px;
      left: 10px;
      top: -3px;
      color: $color1;
      &.icon-food{
        left: 7px;
      }
    }
  }
}