.wp-marketing-materials{

  .heading{
    font-size: 24px;
    margin: 0px 0px 15px 0px;
  }

  .content{
    font-size: 17px;
    margin-bottom: 15px;
  }

  .marketing-section{
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color18;

    &:last-child{
      padding: 0;
      margin: 0;
      border: none;
    }

    .title{
      margin: 0px;
      color: $color3;
      font-size: 18px;
      font-family: $font2-bold;
    }

    .description{
      font-size: 17px;
      margin: 5px 0px 0px 0px;
    }

    .tiles{
      margin: 15px 0px 0px 0px;
    }

    .tile{
      overflow: hidden;
      margin-top: 15px;
      border-radius: 4px;
      position: relative;
      padding-bottom: 55px;
      background-color: $color7;
    }

    .btn{
      left: 15px;
      position: absolute;
      width: calc((100%) - 30px);
    }

    .download-1{
      bottom: 15px;
    }

    .two-cta{
      padding-bottom: 110px;
      .download-1{
        bottom: 69px;
      }
      .download-2{
        bottom: 15px;
      }
    }

    .image{
      height: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .information{
      padding: 15px;
      font-size: 17px;
    }

    .comment{
      margin: 0px;
      font-family: $font1-bold;
    }
  }

  @include media(">=small") {
    .content{
      font-size: 14px;
      margin-bottom: 35px;
    }

    .marketing-section{
      .description{
        font-size: 14px;
      }

      .tiles{
        display: flex;
        flex-wrap: wrap;
      }

      .image{
        height: 160px;
      }

      .information{
        font-size: 14px;
      }

      .tile{
        width: 48%;
        margin-right: 2%;
        margin-top: 0px;

        &:nth-child(n+3){
          margin-top: 15px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  }

  @include media(">=medium") {
    .marketing-section{
      .tile{
        width: 32%;

        &:nth-child(n+3){
          margin-top: 0px;
        }

        &:nth-child(n+4){
          margin-top: 15px;
        }

        &:nth-child(2n) {
          margin-right: 2%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(3n+1) {
          margin-left: 0;
        }
      }
    }
  }
}