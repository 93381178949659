/* Two columns stacked layout */

#african-travel-cares,
#awards-accolades,
#careers,
#our-partners,
#social-media-responsibility,
#terms-conditions,
.treadright-foundation,
#travel-corporation,
#team-experts,
#author-archive {
  .center-wrapper {
    overflow: hidden;
    padding: 60px 0;
  }

  .panel-col-first,
  .panel-col-last {
    padding: 0 15px;
    width: 100%;
  }

  @include media(">=small") {
    .panel-col-first {
      width: 30%;
    }

    .panel-col-last {
      width: 70%;
    }

    .wp-static-content {
      .wrapper {
        width: 75%;
      }
    }
  }

  @include media(">=medium") {
    .panel-col-first {
      padding-left: 10%;
    }
  }

  @include media(">=large") {
    .panel-col-first {
      padding-left: 15%;
    }
  }
}
